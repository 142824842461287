import React, { useContext } from "react";
import Carousel from "react-bootstrap/Carousel";
import { ContextApi } from "../../ContextApi/AppProvider";

const Banner = () => {
  const { ApiData } = useContext(ContextApi);

  return (
    <>
      <Carousel>
        {ApiData.banner.map((items, index) => (
          <Carousel.Item key={index}>
            <section className="hero-banner">
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-lg-12 px-0">
                    <div className="hero-play">
                      <img src={items.image} alt="hero-img img-fluid" loading="lazy"/>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

export default Banner;
