import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ContextApi } from "../../ContextApi/AppProvider";
import {
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import { CiFacebook, CiLinkedin } from "react-icons/ci";
import { setFavicon } from "../../../utils/SetFavicon"; 

const Footer = () => {
  const { ApiData } = useContext(ContextApi);
  useEffect(() => {
        setFavicon(ApiData.siteConfig.favicon);
  }, []);
  const socialIcons = {
    instagram: <FaInstagram />,
    whatsapp: <FaWhatsapp />,
    linkedin: <CiLinkedin />,
    facebook: <CiFacebook />
  };
  return (
    <>
      <section className="footer py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-12  footer-category">
            <div>
                <p className="mb-4">Categories <span className="footer-see-all btn text-success"><Link> See All</Link> </span> </p>
            </div>
              <ul className="main-list">
                {ApiData.productCategories.slice(0,10).map((item) => (
                  <li key={item.id}>
                    <Link
                      className="link-primary"
                      to={`/category/${item.slug}`}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <ul className="main-list ps-0">
                <p className="mb-4">Useful Links</p>
                <li>
                  <Link className="link-primary" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="link-primary" to="/about-us">
                    About
                  </Link>
                </li>
                <li>
                  <Link className="link-primary" to="/contact-us">
                    Contact
                  </Link>
                </li>

              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <ul className="main-list ps-0 ">
                <p className="mb-4">Help & Supports</p>
                <li>
                  <Link className="link-primary" to="/privacy-policy">
                    Privacy & Policy
                  </Link>
                </li>
                <li>
                  <Link className="link-primary" to="/terms-condition">
                    Terms & Condition
                  </Link>
                </li>
                <li>
                  <Link className="link-primary" to="/feedback">
                    FeedBack
                  </Link>
                </li>
                <li>
                  <Link className="link-primary" to={`/faqs`}>FAQ</Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-4 col-12">
              <ul className="main-list ps-0">
                <p className="mb-4">contact us</p>
                <li>
                  <Link > 
                    WhatsApp Us : <span className="link-primary_number">{ApiData.siteConfig.primary_mobile}</span>
                  </Link>
                </li>
                <li>
                  <Link >
                    Call Us : <span className="link-primary_number">{ApiData.siteConfig.primary_mobile}</span>
                  </Link>
                </li>
                <li>
                  <Link >
                    Email : <span className="link-primary_number">{ApiData.siteConfig.email}</span>
                  </Link>
                </li>
                <li>
                  <Link className="link-primary_number">
                    Address : <span >{ApiData.siteConfig.address}</span>
                  </Link>
                </li>
                <li>
                  {ApiData.socialMedia.map((media) => (
                    <Link
                      key={media.id}
                      to={media.url}
                      className="fs-2 mx-2 link-primary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {socialIcons[media.name]}
                    </Link>
                  ))}
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- bootom-footer --> */}
        </div>
      </section>
      <section className="bottom-line-footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 px-0">
              <div className="bottom-wrapper">
                <p>© 2024 All Rights Reserved. Online Shopping Ltd.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
