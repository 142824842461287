import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ContextApi } from "../../ContextApi/AppProvider.jsx";
import { CartContextApi } from "../../ContextApi/CartContext.jsx";
import ProductCartButton from "./ProductCartButton.jsx";
import { MetaTags } from "react-meta-tags";
import axios from "axios";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa";

const ProductDetails = () => {
  const { toggle, productQuantity, handleToggle, handleQuantityChange } =
    useContext(CartContextApi);
  const { domainApi } = useContext(ContextApi);
  const [details, setDetails] = useState([]);
  const [categories, setCategories] = useState([]);
  const { ApiData } = useContext(ContextApi);
  const { slug } = useParams();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const res = await axios.get(`${domainApi}product/product/${slug}/`);
      if (res.request.status === 200) {
        setDetails(res.data);
      }
    } catch (error) {
      console.log("there is a problem with category slug api", error);
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const leftArrow = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex > 0 ? prevIndex - 1 : details.images.length - 2;
    });
  };

  const rightArrow = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < details.images.length - 2 ? prevIndex + 1 : 0
    );
  };

  const clickimg = (image) => {
    const index = details.images.findIndex((img) => img.image === image);
    if (index !== -1) {
      setCurrentIndex(index);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          {details.meta_title ||
            "QuickEcommerce | Your One-Stop Shop for Everything"}
        </title>
        <meta
          name="description"
          content={
            details.meta_description ||
            "Discover a wide range of products at unbeatable prices. Shop now on QuickEcommerce!"
          }
        />
        <meta
          name="keywords"
          content={
            details.meta_keywords ||
            "shopping, ecommerce, online store, buy online, best deals"
          }
        />
        <meta
          property="og:title"
          content={
            details.meta_title ||
            "QuickEcommerce | Your One-Stop Shop for Everything"
          }
        />
        <meta property="og:image" content="path/to/image.jpg" />
      </Helmet>

      {Object.keys(details).length !== 0 ? (
        <section className="product-detail py-4" key={details.id}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="product-left-image">
                  <div className="product-main-img">
                    <img
                      src={
                        details.images.length > 1
                          ? details.images[currentIndex].image
                          : details.thumbnail
                      }
                      className="imagebox"
                      alt={details.title}
                      title={details.title}
                      loading="lazy"
                    />
                  </div>
                  <div className="imagehover">
                    <div
                      className="left-arrow"
                      id="left-arrow"
                      onClick={() => leftArrow()}
                    >
                      <i className="fa-solid fa-chevron-left"></i>
                    </div>
                    <div className="img-slider" id="tabs">
                      {details.images && details.images.length > 0 ? (
                        details.images.slice(0, 5).map((item, index) => (
                          <div
                            key={index}
                            alt={`${index}${item.id}`}
                            className={`clickimg-div ${
                              index === currentIndex ? "active-slide" : ""
                            }`}
                            onClick={() => clickimg(item.image)}
                          >
                            <img
                              src={item.image}
                              alt={`${index}${item.id}`}
                              className={`clickimg ${
                                index === currentIndex ? "active" : ""
                              }`}
                              onClick={() => clickimg(`${item.image}`)}
                              loading="lazy"
                            />
                          </div>
                        ))
                      ) : (
                        <p>No images found</p>
                      )}
                    </div>
                    <div
                      className="right-arrow"
                      id="right-arrow"
                      onClick={() => rightArrow()}
                    >
                      <i className="fa-solid fa-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              {/* product details */}
              <div className="col-lg-6 col-md-12 col-sm-12 my-3">
                <div className="product-detail-section">
                  <div className="product-container">
                    <div className="product-detail-wrapper">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/" title="Home">
                              Home
                            </Link>
                          </li>
                          <li className="breadcrumb-item ">
                            <Link to={`/category/${details.category.slug}`}>
                              {details.category.title}
                            </Link>
                          </li>
                          {/* <li className="breadcrumb-item">
                            <Link>{details.subcategory.title}</Link>
                          </li> */}
                          <li className="breadcrumb-item ">
                            <Link> {details.title}</Link>
                          </li>
                        </ol>
                      </nav>

                      <div className="product-title">
                        <p className="title-ptop " title={details.title}>
                          <i className="fa-solid fa-circle pe-1 "></i>{" "}
                          {details.title}
                          <span className="ps-2">
                            {details.net_weight} {details.unit.abbreviation}
                          </span>
                          <span className="quantity-in-stock">
                            {details.is_available
                              ? `Available In Stock - ${details.quantity_in_stock}`
                              : "Out Of Stock"}
                          </span>
                        </p>
                        <div className="right-cart">
                          <div className="price-list price">
                            {details.discount &&
                            Math.floor(details.discount) <= 0 ? (
                              <p className="mb-0 fs-6" id="basePrice">
                                MRP: ₹ {details.offer_price}
                              </p>
                            ) : (
                              <>
                                <p
                                  className="mb-0 fs-6 text-decoration-line-through "
                                  id="basePrice"
                                >
                                  MRP: ₹ {details.price}
                                </p>
                                Offer Price: ₹ {details.offer_price}
                                <span className="discount-cart ms-1">
                                  {Math.floor(details.discount)}% off
                                </span>
                              </>
                            )}
                          </div>

                          <div className="product-details-crt-plus ">
                            {details.is_available && details.is_available ? (
                              <ProductCartButton
                                value={details}
                                productQuantity={productQuantity}
                                toggle={toggle}
                                handleQuantityChange={handleQuantityChange}
                                handleToggle={handleToggle}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- product-details --> */}
                    <div className="listing-bottom mt-3">
                      <p className="fs-5 m-2 fw-bold">Product Details:-</p>
                      <div>
                        <div className="my-2 fw-bold">
                          Net Weight:{" "}
                          <p className="item-details-info">
                            {details.net_weight} {details.unit.abbreviation}
                          </p>
                        </div>
                        <div className="my-2 fw-bold">
                          Expiry Date:{" "}
                          <p className="item-details-info">
                            {details.expiry_date}
                          </p>
                        </div>
                        <div className="my-2 fw-bold">
                          Quantity In Stock:{" "}
                          <p className="item-details-info">
                            {details.quantity_in_stock}
                          </p>
                        </div>
                        <div className="my-2 fw-bold">
                          Packaging Type:{" "}
                          <p className="item-details-info">
                            {details.packaging_type.type}
                          </p>
                        </div>
                        <div className="my-2 fw-bold">
                          Customer Support:{" "}
                          <p className="item-details-info">
                            {details.customer_care}
                          </p>
                        </div>
                        <div className="my-2 fw-bold">
                          Manufacturer Details:{" "}
                          <p className="item-details-info">
                            {details.manufacturer_detail}{" "}
                          </p>
                        </div>
                        <div className="my-2 fw-bold">
                          Country Of Origin:{" "}
                          <p className="item-details-info">
                            {details.country_of_origin.name}{" "}
                          </p>
                        </div>
                        <div className="my-2 fw-bold">
                          Description:{" "}
                          <p
                            className="item-details-info"
                            dangerouslySetInnerHTML={{
                              __html: details.description,
                            }}
                          />{" "}
                        </div>
                        <div className="my-2 fw-bold">
                          Key Features:{" "}
                          <p
                            className="item-details-info"
                            dangerouslySetInnerHTML={{
                              __html: details.description,
                            }}
                          />{" "}
                        </div>
                        <div className="my-2 fw-bold">
                          Ingredients:{" "}
                          <p
                            className="item-details-info"
                            dangerouslySetInnerHTML={{
                              __html: details.key_features,
                            }}
                          />{" "}
                        </div>
                        <div className="my-2 fw-bold">
                          Short Description:{" "}
                          <p
                            className="item-details-info"
                            dangerouslySetInnerHTML={{
                              __html: details.short_description,
                            }}
                          />{" "}
                        </div>
                        <div className="my-2 fw-bold">
                          Shelf Life:{" "}
                          <p className="item-details-info">
                            {details.shelf_life}{" "}
                          </p>
                        </div>
                        <div className="my-2 fw-bold">
                          Disclaimer:{" "}
                          <p className="item-details-info">
                            {details.disclaimer}{" "}
                          </p>
                        </div>
                        <div className="my-2 fw-bold">
                          Return Policy:{" "}
                          <p className="item-details-info">
                            {details.return_policy}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <p>Details is empty</p>
      )}

      {/* <section className="about-home py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {Relatedproduct.map((item) => (
                  <div
                    className="dz-img-box style-4 box-hover col-lg-3 mb-2 "
                    key={item.id}
                  >
                    <Link to={`/product/${item.slug}`}>
                      <div className="menu-detail">
                        <div className="dz-media">
                          <img
                            src={item.thumbnail}
                            alt="/"
                          />
                        </div>
                        <div className="dz-content">
                          <h5
                            className="title product-cart-item-name"
                            title={details.title}
                          >
                            {item.title}
                            
                          </h5>
                          <p>
                            {item.net_weight}{" "}
                            <span>{item.unit.abbreviation}</span>
                          </p>
                        </div>
                      </div>
                      <div className="menu-footer">
                        <span>Regular Price</span>
                        <h5 className="price">
                          ₹
                          <span className="fs-6 text-decoration-line-through">
                            {item.price}{" "}
                          </span>
                          {item.offer_price}<span className="discount-cart">
                            {Math.floor(item.discount)}% off
                          </span>
                        </h5>
                      </div>
                    </Link>
                    <div className="product-details-crt-plus ">
                      <ProductCartButton
                        value={item}
                        productQuantity={productQuantity}
                        toggle={toggle}
                        handleQuantityChange={handleQuantityChange}
                        handleToggle={handleToggle}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default ProductDetails;
