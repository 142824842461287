import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CartContextApi } from "../../ContextApi/CartContext";
import ProductCartButton from "./ProductCartButton";
import Swal from "sweetalert2";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { MdOutlineNotificationsActive } from "react-icons/md";

const ProductNormalCart = ({ value }) => {
  const { toggle, productQuantity, handleToggle, handleQuantityChange } =
    useContext(CartContextApi);
  const token = localStorage.getItem("token");
  const [favourite, setFavourite] = useState(false);
  // console.log("productNormancart render");
  const product = useMemo(() => value, [value]);
  return (
    <div className="d-flex flex-wrap justify-content-lg-start justify-content-center">
      {product &&
        product.map((item) => (
          <div
            className={`dz-img-box style-4 box-hover col-lg-3 col-md-4 col-sm-6 mb-4  mx-2 ${
              item.is_available ? "" : "dz-img-box-before"
            }`}
            key={item.id}
          >
            <div className="menu-detail">
              <div className="dz-media">
                <img src={item.thumbnail} alt={item.title} title={item.title} loading="lazy"/>
              </div>
              <div className="dz-content">
                <Link to={`/product/${item.slug}`} title={item.title}>
                  <h5
                    className="title product-cart-item-name"
                    title={item.title}
                  >
                    {item.title}
                  </h5>
                </Link>
                <p>
                  {item.net_weight} <span>{item.unit.abbreviation}</span>
                </p>
              </div>
            </div>
            {/* {token ? (
              <div className="favourite-icon">
                {favourite ? (
                  <FaHeart onClick={() => setFavourite(false)} />
                ) : (
                  <FaRegHeart onClick={() => setFavourite(true)} />
                )}
              </div>
            ) : (
              ""
            )} */}
            <div className="menu-footer">
              <span>Regular Price</span>
              <div className="price">
                ₹
                {item.discount && Math.floor(item.discount) <= 0 ? (
                  <span className="price-without-discount">
                    {item.offer_price}
                  </span>
                ) : (
                  <>
                    <span className="fs-6 text-decoration-line-through price-and-discount">
                      {item.price}
                    </span>
                    {item.offer_price}
                    <span className="discount-cart">
                      {Math.floor(item.discount)}% off
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="product-details-crt-plus ">
              {item.is_available && item.is_available ? (
                <ProductCartButton
                  value={item}
                  productQuantity={productQuantity}
                  toggle={toggle}
                  handleQuantityChange={handleQuantityChange}
                  handleToggle={handleToggle}
                />
              ) : (
                <div
                  className="notify-btn"
                  onClick={() => {
                    const Toast = Swal.mixin({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 1500,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                      },
                    });
                    Toast.fire({
                      icon: "success",
                      title: "Notifyed",
                      // text: response.data.message,
                    });
                  }}
                >
                  <MdOutlineNotificationsActive className="fs-5" /> Notify
                </div>
              )}
              {item.is_available && item.is_available ? (
                ""
              ) : (
                <p className="out-of-stock-p">Out Of Stock</p>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default React.memo(ProductNormalCart);
