import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

const customStyles = {
  content: {
    top: "25%",
    left: "20%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    padding: "20px",
    borderRadius: "10px",
    width: "300px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

Modal.setAppElement("#root");

const OrderConfirmModel = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOrderSuccess = () => {
    setIsModalOpen(true);
  };
  const navigates = () => {
    setIsModalOpen(false);
    navigate("/user-dashboard/my-order");
  };
  useEffect(() => {
    handleOrderSuccess();
  }, []);

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles}
        contentLabel="Order Confirmation"
      >
        <div>
          <div style={{ fontSize: "40px", color: "green" }}>✔️</div>
          <h2 className="mt-3">Order Successful</h2>
          <p className="mt-3">Thank you so much for your order.</p>
          <button
            style={{
              padding: "10px 20px",
              backgroundColor: "green",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginTop: "16px",
            }}
            onClick={navigates}
          >
            CHECK STATUS
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default OrderConfirmModel;