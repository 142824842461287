import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CartContextApi } from "../../ContextApi/CartContext";
import Slider from "react-slick";
import ProductCartButton from "./ProductCartButton";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import Swal from "sweetalert2";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { ContextApi } from "../../ContextApi/AppProvider";

const ProductCart = ({ value }) => {
  const { toggle, productQuantity, handleToggle, handleQuantityChange } =
    useContext(CartContextApi);
    // const {addToWishlist, deleteFromWishlist, wishlist} = useContext(ContextApi);
  const [favourite, setFavourite] = useState(false);
  const token = localStorage.getItem("token");
  // const [product, setProduct] = useState([]);

  
  // console.log("productcart render");
  const product = useMemo(() => value, [value]);

  // useEffect(() => {
  //   setProduct(value);
  // }, []);

  // const handleWishlist = (id, condition) =>{

  //   if(condition){
  //     setFavourite(true)
  //     addToWishlist(id);
  //     console.log( id,"true")
  //   }else{
  //     setFavourite(false)
  //     deleteFromWishlist(id);
  //     console.log(id,"flase")
  //   }
  // }

  const settings = useMemo(
    () => ({
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      speed: 1000,
      // infinite: true,
      // autoplay: true,
      // autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }),
    []
  );

  return (
    <>
      <section className="products-section py-lg-3 py-md-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-between align-items-center my-3">
              <h3 className="fea-sec-top">{product?.[0]?.category?.title}</h3>
              <Link
                to={`/all-products/${product?.[0]?.category?.slug}`}
                className="btn text-success fw-bolder fs-6"
              >
                See All
              </Link>{" "}
            </div>
            <div className="col-lg-12">
              <div className="wrapper">
                <Slider {...settings}>
                  {product &&
                    product.map((item) => (
                      <div
                        className={`dz-img-box style-4 box-hover ${
                          item.is_available ? "" : "dz-img-box-before"
                        }`}
                        key={item.id}
                      >
                        <div className="menu-detail">
                          <div className="dz-media">
                            <Link
                              to={`/product/${item.slug}`}
                              title={item.title}
                            >
                              <img
                                src={item.thumbnail}
                                alt={item.title}
                                title={item.title}
                                loading="lazy"
                              />
                            </Link>
                          </div>
                          <div className="dz-content">
                            <Link
                              to={`/product/${item.slug}`}
                              title={item.title}
                            >
                              <h5
                                className="title product-cart-item-name"
                                title={item.title}
                              >
                                {item.title}
                              </h5>
                            </Link>
                            <p>
                              {item.net_weight}{" "}
                              <span>{item.unit.abbreviation}</span>
                            </p>
                          </div>
                        </div>
                        {/* {token ? (
                          <div className="favourite-icon">
                            {favourite ? (
                              <FaHeart onClick={()=>handleWishlist(item.id, false)}/>
                             
                            ) : (
                              <FaRegHeart onClick={()=>handleWishlist(item.id, true)} />
                            )}
                          </div>
                        ) : (
                          ""
                        )} */}

                        <div className="menu-footer">
                          <span>Regular Price</span>
                          <div className="price">
                            ₹
                            {item.discount && Math.floor(item.discount) <= 0 ? (
                              <span className="price-without-discount">
                                {item.offer_price}
                              </span>
                            ) : (
                              <>
                                <span className="fs-6 text-decoration-line-through price-and-discount">
                                  {item.price}
                                </span>
                                {item.offer_price}
                                <span className="discount-cart">
                                  {Math.floor(item.discount)}% off
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        {item.is_available && item.is_available ? (
                          <ProductCartButton
                            value={item}
                            productQuantity={productQuantity}
                            toggle={toggle}
                            handleQuantityChange={handleQuantityChange}
                            handleToggle={handleToggle}
                          />
                        ) : (
                          <div
                            className="notify-btn"
                            onClick={() => {
                              const Toast = Swal.mixin({
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                  toast.onmouseenter = Swal.stopTimer;
                                  toast.onmouseleave = Swal.resumeTimer;
                                },
                              });
                              Toast.fire({
                                icon: "success",
                                title: "Notifyed",
                                // text: response.data.message,
                              });
                            }}
                          >
                            <MdOutlineNotificationsActive className="fs-5" />{" "}
                            Notify
                          </div>
                        )}
                        {item.is_available && item.is_available ? (
                          ""
                        ) : (
                          <p className="out-of-stock-p">Out Of Stock</p>
                        )}
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(ProductCart);
