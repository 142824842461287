import React from "react";

const ProcessingBtn = () => {
  return (
    <button className="btn processing-btn " disabled>
      <span className="spinner-border spinner-border-sm"></span> Processing...
    </button>
  );
};

export default ProcessingBtn;
