import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ContextApi } from "../../ContextApi/AppProvider";

const ResetPassword = () => {
  const { domainApi } = useContext(ContextApi);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    newpassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfPassword, setShowNewConfPassword] = useState(false);
  // const [phone, setphone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [forgotPassword, setForgotPasswprd] = useState(false);
  const [formData, setFormData] = useState({
    newpassword: "",
    confirmnewpassword: "",
  });
  // const [phoneNumber, setPhoneNumber] = useState(loginData.phone);
  // console.log(password)
  // console.log(confirmPassword)
  // console.log(formData)

  useEffect(() => {
    validatePasswords();
  }, [formData.newpassword, formData.confirmnewpassword]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleNewConfPasswordVisibility = () => {
    setShowNewConfPassword(!showNewConfPassword);
  };

  const validatePasswords = () => {
    if (formData.newpassword !== formData.confirmnewpassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newpassword: "Passwords do not match",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newpassword: "",
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // event.preventDefault();
    setFormData((pre) => ({
      ...pre,
      [name]: value,
    }));
  };

  console.log(formData);
  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      const response = await axios.post(
        `${domainApi}auth/password-reset-form/`,
        formData
      );
      if (response.status === 200) {
        console.log("response", response);
        console.log(response.data, response.data.user.role);
        // navigate(`/user/${response.data.user.role}`);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Log-In successfully",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "server error try again",
          text: response.data.message,
        });
      }
    } catch (error) {
      console.error("there was a problam with your post operation", error);
    }
  };

  // const handleForgotPassword = async () => {
  //   try {
  //     const response = await axios.post(
  //       "http://127.0.0.1:8000/auth/password-reset/",
  //       { phone: phoneNumber }
  //     );
  //     console.log({ phone: phoneNumber });
  //     if (response.status === 200) {
  //       console.log("response", response);
  //       const Toast = Swal.mixin({
  //         toast: true,
  //         position: "top-end",
  //         showConfirmButton: false,
  //         timer: 3000,
  //         timerProgressBar: true,
  //         didOpen: (toast) => {
  //           toast.onmouseenter = Swal.stopTimer;
  //           toast.onmouseleave = Swal.resumeTimer;
  //         },
  //       });
  //       Toast.fire({
  //         icon: "success",
  //         title: "Reset Your Password",
  //       });
  //       navigate("/signup/login");
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "server error try again",
  //         // text: response.data.message
  //       });
  //     }
  //   } catch (error) {
  //     console.log("there was a problam with your post operation", error);
  //   }
  //   setForgotPasswprd(true);
  // };
  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="col-lg-12 col-12 my-3 d-flex justify-content-center">
            <form className="signup-login-form" onSubmit={handleSubmit}>
              <h3 className="my-2">Create New-Password</h3>
              <div className="form-group">
                <label htmlFor="password">New Password</label>
                <input
                  type={showNewPassword ? "text" : "password"}
                  id="password"
                  name="newpassword"
                  // value={password}
                  onChange={handleChange}
                  placeholder="Enter New password"
                  required
                />
                <button
                  type="button"
                  className="signup_eye_btn"
                  onClick={toggleNewPasswordVisibility}
                >
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              <div className="form-group">
                <label htmlFor="password">Confirm New Password</label>
                <input
                  type={showNewConfPassword ? "text" : "password"}
                  id="password"
                  name="confirmnewpassword"
                  placeholder="Enter Confirm New password"
                  // value={password}
                  onChange={handleChange}
                  required
                />
                <button
                  type="button"
                  className="signup_eye_btn"
                  onClick={toggleNewConfPasswordVisibility}
                >
                  {showNewConfPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
                {errors.newpassword && (
                  <span className="error" style={{ color: "red" }}>
                    {errors.newpassword}
                  </span>
                )}
              </div>
              <button type="submit" className="btn-login">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;

//   console.log('Submitted:', { phone, password });
// navigate('/')
