import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { ContextApi } from "../../ContextApi/AppProvider";
import ProcessingBtn from "../../ScrollButton/ProcessingBtn";

const Signup = () => {
  const { domainApi } = useContext(ContextApi);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "+91",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const handleKeyPress = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      const charCode = event.which ? event.which : event.keyCode;
      if (
        (charCode < 65 || charCode > 90) &&
        (charCode < 97 || charCode > 122) &&
        charCode !== 32
      ) {
        event.preventDefault();
      }
    }
    if (name === "phone") {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode === 8 && value.length <= 13) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Phone number must be at least 13 characters long",
        }));
      }
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let newErrors = { ...errors };

    switch (name) {
      case "name":
        const namePattern = /^[a-zA-Z]+( [a-zA-Z]+)*$/;
        if (!value) {
          newErrors[name] = "Name is required";
        } else if (!namePattern.test(value)) {
          newErrors[name] = "Only alphabets and spaces are allowed";
        } else if (value.length < 3 || value.length > 200) {
          newErrors[name] = "Name must be between 3 and 20 characters long";
        } else {
          delete newErrors[name];
        }
        break;

      case "email":
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value && !emailPattern.test(value)) {
          newErrors[name] = "Invalid email address";
        } else {
          delete newErrors[name];
        }
        break;

      case "phone":
        const phonePattern = /^\+91\d{10}$/;
        if (!value || !phonePattern.test(value)) {
          newErrors[name] = "Invalid phone number (10 digits)";
        } else {
          delete newErrors[name];
        }
        break;

      case "password":
        if (!value || value.length < 6) {
          newErrors[name] = "Password must be at least 6 characters long";
        } else {
          delete newErrors[name];
        }
        break;

      case "confirmPassword":
        if (value !== formData.password) {
          newErrors[name] = "Passwords do not match";
        } else {
          delete newErrors[name];
        }
        break;

      default:
        break;
    }

    if (name === "phone" && value.startsWith("+91")) {
      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));
    } else if (name !== "phone") {
      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));
      setErrors(newErrors);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      // navigate(`/otpverify`);
      try {
        const response = await axios.post(
          `${domainApi}auth/register/`,
          formData
        );

        if (response.status === 201) {
          const mobileNumber = formData.phone;
          // console.log(response.data.OTP);

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "SignUp successfully",
          });

          navigate(`/verify-otp`, { state: { mobileNumber } });
        } else {
          Swal.fire({
            icon: "error",
            title: "server error try again",
            // text: response.data.message
          });
        }
      } catch (error) {
        console.error("there was a problam with your post operation", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;
    const namePattern = /^[a-zA-Z]+( [a-zA-Z]+)*$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Name validation
    if (!formData.name) {
      errors.name = "Name is required";
      isValid = false;
    } else if (!namePattern.test(formData.name)) {
      errors.name = "Only alphabets and spaces are allowed";
      isValid = false;
    } else if (formData.name.length < 3 || formData.name.length > 20) {
      errors.name = "Name must be between 3 and 20 characters long";
      isValid = false;
    }

    // Email validation
    if (!formData.email || !emailPattern.test(formData.email)) {
      errors.email = "Invalid email address";
      isValid = false;
    } else if (formData.email.length < 6 || formData.email.length > 255) {
      errors.email = "Email must be between 6 and 25 characters long";
      isValid = false;
    }

    // Phone number validation
    const phonePattern = /^\+91\d{0,10}$/;
    if (formData.phone.length < 13) {
      errors.phone = "Invalid phone number (10 digits)";
      isValid = false;
    } else if (!formData.phone || !phonePattern.test(formData.phone)) {
      errors.phone = "Invalid phone number (10 digits)";
      isValid = false;
    }

    // Password validation
    if (!formData.password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
      isValid = false;
    }

    // Confirm password validation
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfPasswordVisibility = () => {
    setShowConfPassword(!showConfPassword);
  };

  return (
    <>
      <form className="signup-form my-4" onSubmit={handleSubmit}>
        <h3>Sign Up</h3>
        <div className="form-group">
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            required
          />
          {errors.name && (
            <span className="error" style={{ color: "red" }}>
              {errors.name}
            </span>
          )}
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email (Optional)"
            name="email"
            maxLength="255"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && (
            <span className="error" style={{ color: "red" }}>
              {errors.email}
            </span>
          )}
        </div>
        <div className="form-group">
          <input
            type="tel"
            placeholder="Enter phone Number"
            name="phone"
            maxLength="13"
            value={formData.phone}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            required
          />
          {errors.phone && (
            <span className="error" style={{ color: "red" }}>
              {errors.phone}
            </span>
          )}
        </div>
        <div className="form-group signp_input_password">
          <input
            type={showPassword ? "text" : "password"}
            placeholder=" Password"
            name="password"
            maxLength="16"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <button
            type="button"
            className="signup_eye_btn"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
          {errors.password && (
            <span className="error" style={{ color: "red" }}>
              {errors.password}
            </span>
          )}
        </div>
        <div className="form-group">
          <input
            type={showConfPassword ? "text" : "password"}
            placeholder="Confirm Password"
            name="confirmPassword"
            maxLength="16"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
          <button
            type="button"
            className="signup_eye_btn"
            onClick={toggleConfPasswordVisibility}
          >
            {showConfPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
          {errors.confirmPassword && (
            <span className="error" style={{ color: "red" }}>
              {errors.confirmPassword}
            </span>
          )}
        </div>
        {loading ? (
          <ProcessingBtn />
        ) : (
          <button className="mb-3" type="submit">
            Sign Up
          </button>
        )}

        <div className="d-flex my-2 dont-account-signup-login justify-content-center">
          <p>Already have an account? </p>
          <span>
            <Link to="/login">Sign In here</Link>
          </span>
        </div>
        {/* ///////////////////........................  SIGNUP WITH GOOGLE */}
        {/* <GoogleOAuthProvider clientId="YOUR_CLIENT_ID">
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              const decoded = jwtDecode(credentialResponse.credential);
              console.log(decoded);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />
        </GoogleOAuthProvider> */}
      </form>
    </>
  );
};

export default Signup;
