



// import axios from "axios";
// import React, { useContext, useState } from "react";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { Link, useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import LoadingPage from "../../ScrollButton/LoadingPage";
// import { ContextApi } from "../../ContextApi/AppProvider";
// import { CartContextApi } from "../../ContextApi/CartContext";
// import ProcessingBtn from "../../ScrollButton/ProcessingBtn";
// import { useFormik } from "formik";
// import * as Yup from 'yup';

// const LogIn = () => {
//   const { updateToken } = useContext(CartContextApi);
//   const { domainApi } = useContext(ContextApi);
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   // const [errors, setErrors] = useState({});
//   const [showPassword, setShowPassword] = useState(false);
//   const [resetPhone, setResetPhone] = useState({
//     phone: "+91",
//   });
//   const [forgotToggle, setForgotToggle] = useState(true);
//   const [loginData, setLoginData] = useState({
//     phone: "",
//     password: "",
//   });

  
//   const signInSchema = Yup.object({
//     phone:Yup.number().min(10, 'Too Short!').required("Please Enter Your Number"),
//     password:Yup.string().required("Please Enter Your Password")
//   })

//  const {values, errors, handleBlur, handleChange, handleSubmit} = useFormik({
//   initialValues : loginData,
//   validationSchema:signInSchema,
//   onSubmit : (value)=>{
// console.log(value);
//   }
// })
// console.log(errors);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   // const handleKeyPress = (event) => {
//   //   const { name, value } = event.target;
//   //   if (name === "phone") {
//   //     const charCode = event.which ? event.which : event.keyCode;
//   //     if (charCode === 8 && value.length <= 13) {
//   //       setErrors((prevErrors) => ({
//   //         ...prevErrors,
//   //         [name]: "Phone number must be at least 13 characters long",
//   //       }));
//   //     }
//   //     if (charCode < 48 || charCode > 57) {
//   //       event.preventDefault();
//   //     }
//   //   }
//   //   if (loginData.phone.length < 12) {
//   //     errors.phone = "Invalid phone number (10 digits)";
//   //   } else {
//   //     errors.phone = "";
//   //   }
//   //   if (resetPhone.phone.length < 12) {
//   //     errors.resetphone = "Invalid phone number (10 digits)";
//   //   } else {
//   //     errors.resetphone = "";
//   //   }
//   // };

//   // const handleChange = (event) => {
//   //   const { name, value } = event.target;
//   //   if (name === "phone" && value.startsWith("+91")) {
//   //     setLoginData((pre) => ({
//   //       ...pre,
//   //       [name]: value,
//   //     }));
//   //   } else if (name !== "phone") {
//   //     setLoginData((pre) => ({
//   //       ...pre,
//   //       [name]: value,
//   //     }));
//   //     setErrors(errors);
//   //   }
//   // };
//   // const handleResetChange = (event) => {
//   //   const { name, value } = event.target;
//   //   if (name === "phone" && value.startsWith("+91")) {
//   //     setResetPhone((pre) => ({
//   //       ...pre,
//   //       [name]: value,
//   //     }));
//   //   }
//   // };

//   // const handleLogin = async (e) => {
//   //   e.preventDefault();
//   //   setLoading(true);
//   //   try {
//   //     const response = await axios.post(`${domainApi}auth/login/`, loginData);
//   //     if (response.status === 200) {
//   //       if (
//   //         response.data.user.is_verified === true &&
//   //         response.data.user.role === "CU"
//   //       ) {
//   //         localStorage.setItem("token", response.data.token);
//   //         localStorage.setItem("userid", response.data.user.id);
//   //         const get_token = localStorage.getItem("token");
//   //         if (get_token) {
//   //           updateToken(get_token);
//   //           navigate(`/`);
//   //           const Toast = Swal.mixin({
//   //             toast: true,
//   //             position: "top-end",
//   //             showConfirmButton: false,
//   //             timer: 3000,
//   //             timerProgressBar: true,
//   //             didOpen: (toast) => {
//   //               toast.onmouseenter = Swal.stopTimer;
//   //               toast.onmouseleave = Swal.resumeTimer;
//   //             },
//   //           });
//   //           Toast.fire({
//   //             icon: "success",
//   //             title: "Log-In successfully",
//   //           });
//   //         }
//   //         // loginUserData(response.data, response.data.user);
//   //       } else {
//   //         const Toast = Swal.mixin({
//   //           toast: true,
//   //           position: "top-end",
//   //           showConfirmButton: false,
//   //           timer: 3000,
//   //           timerProgressBar: true,
//   //           didOpen: (toast) => {
//   //             toast.onmouseenter = Swal.stopTimer;
//   //             toast.onmouseleave = Swal.resumeTimer;
//   //           },
//   //         });
//   //         Toast.fire({
//   //           icon: "error",
//   //           title: "User Not Found",
//   //         });
//   //       }
//   //     } else {
//   //       Swal.fire({
//   //         icon: "error",
//   //         title: "server error try again",
//   //         // text: response.data.message
//   //       });
//   //     }
//   //   } catch (error) {
//   //     console.error("there was a problam with your post operation", error);
//   //     const Toast = Swal.mixin({
//   //       toast: true,
//   //       position: "top-end",
//   //       showConfirmButton: false,
//   //       timer: 2000,
//   //       timerProgressBar: true,
//   //       didOpen: (toast) => {
//   //         toast.onmouseenter = Swal.stopTimer;
//   //         toast.onmouseleave = Swal.resumeTimer;
//   //       },
//   //     });
//   //     Toast.fire({
//   //       icon: "error",
//   //       text: "Invalid credentials !",
//   //       title: error.response.data.details,
//   //     });
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // };

//   // const handleForgotPassword = async () => {
//   //   try {
//   //     setLoading(true);
//   //     const response = await axios.post(
//   //       `${domainApi}auth/password-reset/`,
//   //       resetPhone.phone
//   //     );

//   //     if (response.status === 200) {
//   //       const Toast = Swal.mixin({
//   //         toast: true,
//   //         position: "top-end",
//   //         showConfirmButton: false,
//   //         timer: 2000,
//   //         timerProgressBar: true,
//   //         didOpen: (toast) => {
//   //           toast.onmouseenter = Swal.stopTimer;
//   //           toast.onmouseleave = Swal.resumeTimer;
//   //         },
//   //       });
//   //       Toast.fire({
//   //         icon: "success",
//   //         title: "Reset Your Password",
//   //       });
//   //       // navigate("/reset-password");
//   //     } else {
//   //       Swal.fire({
//   //         icon: "error",
//   //         title: "server error try again",
//   //         // text: response.data.message
//   //       });
//   //     }
//   //   } catch (error) {
//   //     console.log("there was a problam with your post operation", error);
//   //   } finally {
//   //     setLoading(false);
//   //   }

//   //   // setForgotToggle(true);
//   // };

//   return (
//     <>
//       {forgotToggle ? (
//         <div className="container my-3">
//           <div className="row">
//             <div className="col-lg-12 d-flex justify-content-center">
//               <form className="signup-login-form" onSubmit={handleSubmit}>
//                 <h3>Sign In</h3>
//                 <div className="form-group">
//                   <label htmlFor="mobileNumber">Mobile Number</label>
//                   <input
//                     type="tel"
//                     id="mobileNumber"
//                     name="phone"
//                     maxLength="13"
//                     // onKeyPress={handleKeyPress}
//                     value={values.phone}
//                     onChange={handleChange}
//                     placeholder="Enter your mobile number"
//                     onBlur={handleBlur}
//                     // readOnly={loginData.phone.length <= 3}
//                     required
//                   />
//                   {errors.phone && (
//                     <span className="error" style={{ color: "red" }}>
//                       {errors.phone}
//                     </span>
//                   )}
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="password">Password</label>
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     id="password"
//                     name="password"
//                     value={values.password}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     placeholder="Enter your password"
//                     required
//                   />
//                   {errors.password && (
//                     <span className="error" style={{ color: "red" }}>
//                       {errors.password}
//                     </span>
//                   )}
//                   <button
//                     type="button"
//                     className="signup_eye_btn"
//                     onClick={togglePasswordVisibility}
//                   >
//                     {showPassword ? <FaEyeSlash /> : <FaEye />}
//                   </button>
//                 </div>
//                 <div className="forgot-password mb-2">
//                   <button type="button" onClick={() => setForgotToggle(false)}>
//                     Forgot Password?
//                   </button>
//                 </div>
//                 {loading ? (
//                   <ProcessingBtn />
//                 ) : (
//                   <button
//                     type="submit"
//                     className="btn-login"
//                     // onClick={handleLogin}
//                   >
//                     Sign In
//                   </button>
//                 )}

//                 <div className="d-flex my-2 dont-account-signup-login justify-content-center">
//                   <p>Don't have an account? </p>{" "}
//                   <span>
//                     <Link to="/signup"> Sign Up here</Link>
//                   </span>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       ) : (
//         {/* <div className="container">
//           <div className="row">
//             <div className="col-lg-12 col-12 my-3">
//               <div className="col-lg-12 d-flex justify-content-center">
//                 <form className="signup-login-form">
//                   <h3>Reset Password</h3>
//                   <div className="form-group">
//                     <label htmlFor="mobileNumber">Mobile Number</label>
//                     <input
//                       type="tel"
//                       id="mobileNumber"
//                       name="phone"
//                       maxLength="13"
//                       placeholder="Enter your mobile number"
//                       onKeyPress={handleKeyPress}
//                       value={resetPhone.phone}
//                       onChange={handleResetChange}
//                       required
//                     />
//                     {errors.resetphone && (
//                       <span className="error" style={{ color: "red" }}>
//                         {errors.resetphone}
//                       </span>
//                     )}
//                   </div>
//                   {loading ? (
//                     <ProcessingBtn />
//                   ) : (
//                     <button
//                       type="submit"
//                       className="btn-login"
//                       onClick={handleForgotPassword}
//                     >
//                       Reset Password
//                     </button>
//                   )}
//                 </form>
//               </div>
//             </div>{" "}
//           </div>
//         </div> */}
//       )}
//     </>
//   );
// };

// export default LogIn;


























import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import LoadingPage from "../../ScrollButton/LoadingPage";
import { ContextApi } from "../../ContextApi/AppProvider";
import { CartContextApi } from "../../ContextApi/CartContext";
import ProcessingBtn from "../../ScrollButton/ProcessingBtn";


const LogIn = () => {
  const { updateToken } = useContext(CartContextApi);
  const { domainApi } = useContext(ContextApi);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [resetPhone, setResetPhone] = useState({
    phone: "+91",
  });
  const [forgotToggle, setForgotToggle] = useState(true);
  const [loginData, setLoginData] = useState({
    phone: "+91",
    password: "",
  });

  useEffect(()=>{

  },[])
  console.log(("login"))
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleKeyPress = (event) => {
    const { name, value } = event.target;
    if (name === "phone") {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode === 8 && value.length <= 13) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Phone number must be at least 13 characters long",
        }));
      }
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    }
    if (loginData.phone.length < 12) {
      errors.phone = "Invalid phone number (10 digits)";
    } else {
      errors.phone = "";
    }
    if (resetPhone.phone.length < 12) {
      errors.resetphone = "Invalid phone number (10 digits)";
    } else {
      errors.resetphone = "";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "phone" && value.startsWith("+91")) {
      setLoginData((pre) => ({
        ...pre,
        [name]: value,
      }));
    } else if (name !== "phone") {
      setLoginData((pre) => ({
        ...pre,
        [name]: value,
      }));
      setErrors(errors);
    }
  };
  const handleResetChange = (event) => {
    const { name, value } = event.target;
    if (name === "phone" && value.startsWith("+91")) {
      setResetPhone((pre) => ({
        ...pre,
        [name]: value,
      }));
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${domainApi}auth/login/`, loginData);
      if (response.status === 200) {
        if (
          response.data.user.is_verified === true &&
          response.data.user.role === "CU"
        ) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userid", response.data.user.id);
          const get_token = localStorage.getItem("token");
          if (get_token) {
            updateToken(get_token);
            navigate(`/`);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              icon: "success",
              title: "Log-In successfully",
            });
          }
          // loginUserData(response.data, response.data.user);
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "User Not Found",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "server error try again",
          // text: response.data.message
        });
      }
    } catch (error) {
      console.error("there was a problam with your post operation", error);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "error",
        text: "Invalid credentials !",
        title: error.response.data.details,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${domainApi}auth/password-reset/`,
        resetPhone.phone
      );

      if (response.status === 200) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Reset Your Password",
        });
        // navigate("/reset-password");
      } else {
        Swal.fire({
          icon: "error",
          title: "server error try again",
          // text: response.data.message
        });
      }
    } catch (error) {
      console.log("there was a problam with your post operation", error);
    } finally {
      setLoading(false);
    }

    // setForgotToggle(true);
  };

  return (
    <>
      {forgotToggle ? (
        <div className="container my-3">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <form className="signup-login-form">
                <h3>Sign In</h3>
                <div className="form-group">
                  <label htmlFor="mobileNumber">Mobile Number</label>
                  <input
                    type="tel"
                    id="mobileNumber"
                    name="phone"
                    maxLength="13"
                    onKeyPress={handleKeyPress}
                    value={loginData.phone}
                    onChange={handleChange}
                    placeholder="Enter your mobile number"
                    // readOnly={loginData.phone.length <= 3}
                    required
                  />
                  {errors.phone && (
                    <span className="error" style={{ color: "red" }}>
                      {errors.phone}
                    </span>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    // value={loginData.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                    required
                  />

                  <button
                    type="button"
                    className="signup_eye_btn"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <div className="forgot-password mb-2">
                  <button type="button" onClick={() => setForgotToggle(false)}>
                    Forgot Password?
                  </button>
                </div>
                {loading ? (
                  <ProcessingBtn />
                ) : (
                  <button
                    type="submit"
                    className="btn-login"
                    onClick={handleLogin}
                  >
                    Sign In
                  </button>
                )}

                <div className="d-flex my-2 dont-account-signup-login justify-content-center">
                  <p>Don't have an account? </p>{" "}
                  <span>
                    <Link to="/signup"> Sign Up here</Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 my-3">
              <div className="col-lg-12 d-flex justify-content-center">
                <form className="signup-login-form">
                  <h3>Reset Password</h3>
                  <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number</label>
                    <input
                      type="tel"
                      id="mobileNumber"
                      name="phone"
                      maxLength="13"
                      placeholder="Enter your mobile number"
                      onKeyPress={handleKeyPress}
                      value={resetPhone.phone}
                      onChange={handleResetChange}
                      required
                    />
                    {errors.resetphone && (
                      <span className="error" style={{ color: "red" }}>
                        {errors.resetphone}
                      </span>
                    )}
                  </div>
                  {loading ? (
                    <ProcessingBtn />
                  ) : (
                    <button
                      type="submit"
                      className="btn-login"
                      onClick={handleForgotPassword}
                    >
                      Reset Password
                    </button>
                  )}
                </form>
              </div>
            </div>{" "}
          </div>
        </div>
      )}
    </>
  );
};

export default LogIn;
