import axios from "axios";
import { useContext, useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import MapContainer from "./MapContainer";
import { FaCcAmazonPay, FaHome, FaPlus } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { CartContextApi } from "../../ContextApi/CartContext";
import { ContextApi } from "../../ContextApi/AppProvider";
import { MdShoppingCart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { RiEdit2Fill } from "react-icons/ri";
import OrderConfirmModel from "../../ScrollButton/OrderConfirmModel";
import ProcessingBtn from "../../ScrollButton/ProcessingBtn";

const CheckOut = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenOrderConfirm, setIsOpenOrderConfirm] = useState(false);
  const [updateAddress, setUpdateAddress] = useState([]);
  const { cartData } = useContext(CartContextApi);
  const { domainApi } = useContext(ContextApi);
  const token = localStorage.getItem("token");
  const [addresses, setAddresses] = useState([]);
  const [activeAddress, setActiveAddress] = useState([]);
  const { updateLocalStorageOrderConfirm } = useContext(CartContextApi);
  const [chectOutData, setCheckOutData] = useState({
    shipping_address: "",
    payment_method: "",
    items: [],
  });
  const [createdOrder, setCreatedOrder] = useState({
    order: "",
    amount: "",
    payment_method: "",
    razorpay_payment_id: "",
  });
  useEffect(() => {
    fetchAddress();
  }, [isOpen]);
  console.log("checkoutcomponent")

  useEffect(() => {
    const updatedItems = cartData.cart_items
      ? cartData.cart_items.map((item) => ({
          product: item.product.id,
          quantity: item.quantity,
        }))
      : [];

    setCheckOutData((checkOutData) => ({
      ...checkOutData,
      items: updatedItems,
    }));
  }, [cartData, addresses]);

  useEffect(() => {
    if (createdOrder.razorpay_payment_id) {
      completeOrder();
    }
  }, [createdOrder]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheckOutData((checkOutData) => ({
      ...checkOutData,
      [name]: value,
    }));
  };

  const handleAddress = (selectAddress) => {
    setCheckOutData((checkOutData) => ({
      ...checkOutData,
      shipping_address: selectAddress.id,
    }));
    toggleActive(selectAddress.id);
  };

  const toggleActive = (id) => {
    setActiveAddress(id);
  };

  // fetch Address
  const fetchAddress = async () => {
    try {
      const res = await axios.get(`${domainApi}product/shipping-address/`, {
        headers: { Authorization: `TOKEN ${token}` },
      });
      if (res.request.status === 200) {
        setAddresses(res.data);
      }
    } catch (error) {
      console.log("There Was An Problem With Address Api", error);
    }
  };

  // Razonpay start ----------------------
  const createOrder = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${domainApi}product/orders/`,
        chectOutData,
        {
          headers: { Authorization: `TOKEN ${token}` },
        }
      );
      if (response.status === 201) {
        if (response.data.order.payment_method === "online") {
          setCreatedOrder((prevOrder) => ({
            ...prevOrder,
            order: response.data.order.id,
            amount: Number(response.data.order.total_amount),
            payment_method: response.data.order.payment_method,
          }));
          handlePayment(response.data.order);
        } else if (response.data.order.payment_method === "cod") {
          setIsOpenOrderConfirm(true);
          updateLocalStorageOrderConfirm();
          setTimeout(() => {
            setIsOpenOrderConfirm(false);
            navigate(`/user-dashboard/my-order`);
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Error creating order:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = (data) => {
    const totalAmount = Number(data.total_amount) * 100;
    const options = {
      key: "rzp_test_kcSW1LMmxw3sy4", // Replace with your Razorpay key ID
      amount: totalAmount, // Amount in paise (50000 paise = 500 INR)
      currency: "INR",
      name: "Quick E-Commerce",
      description: "ThankYou For Shopping With Us",
      image:
        "https://portal.quickecommerce.m4bistro.in/static/assets/img/q1.png", // Replace with your company logo URL
      handler: function (response) {
        setCreatedOrder((prevOrder) => ({
          ...prevOrder,
          razorpay_payment_id: response.razorpay_payment_id,
        }));
      },
      prefill: {
        name: "Your Name",
        email: "your.email@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Your Address",
      },
      theme: {
        color: "#7655ab",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();

    rzp.on("payment.failed", function (response) {
      // alert(Payment failed. Error: ${response.error.description});
      // Handle payment failure here
    });
  };

  const completeOrder = async () => {
    if (
      !createdOrder.order ||
      !createdOrder.amount ||
      !createdOrder.payment_method ||
      !createdOrder.razorpay_payment_id
    ) {
      console.error("Missing required order data");
      return;
    }

    try {
      const response = await axios.post(
        `${domainApi}product/payment/`,
        createdOrder,
        {
          headers: { Authorization: `TOKEN ${token}` },
        }
      );
      if (response.status === 200) {
        setIsOpenOrderConfirm(true);
        updateLocalStorageOrderConfirm();
        setTimeout(() => {
          setIsOpenOrderConfirm(false);
          navigate(`/user-dashboard/my-order`);
        }, 2000);
      } else {
        console.log("compleate order response", response);
      }
    } catch (error) {
      console.log("Error completing order:", error);
    }
  };

  const handleUpdate = (data) => {
    setUpdateAddress(data);
    openModal();
  };
  const handleAdd = () => {
    setUpdateAddress(null);
    openModal();
  };
  // Modal for location map START
  const Modal = ({ isOpen, onClose }) => {
    const overlayStyle = {
      display: isOpen ? "block" : "none",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 9999,
    };

    const modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      padding: "16px",
      borderRadius: "5px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
      zIndex: 10000,
    };

    return (
      <>
        <div style={overlayStyle} onClick={onClose}>
          <div
            className="row align-items-center address-model"
            style={modalStyle}
            onClick={(e) => e.stopPropagation()}
          >
            <span className="address-close-btn btn-close " onClick={onClose}>
              {" "}
            </span>

            <MapContainer close={onClose} updateAddress={updateAddress} />
          </div>
        </div>
      </>
    );
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  // Modal for location map END

  return (
    <>
      <div className="container">
        <div className="py-3 text-center">
          <h2>Checkout Your Cards</h2>
        </div>
        <div className="row justify-content-center my-2">
          <div className="billing-address col-lg-4 col-md-6 col-sm-12">
            <h4 className="my-3 d-flex align-items-center text-secondary">
              {" "}
              <FaHome className="mx-2" />
              Select Billing address
            </h4>
            <div className="over-height">
              <div className="select-address">
                {addresses && addresses.length > 0
                  ? addresses.map((item) => (
                      <div
                        className={`d-flex shadow-sm m-2 ${
                          activeAddress === item.id ? "active" : ""
                        }`}
                        key={item.id}
                        onClick={() => handleAddress(item)}
                        name="address"
                      >
                        <div className="mx-1 p-2">
                          <IoLocationOutline className="fs-3 m-2" />
                        </div>
                        <div>
                          <div className="d-flex justify-content-between">
                            <h6>{item.address_type}</h6>
                            <span
                              className=""
                              onClick={() => handleUpdate(item)}
                            >
                              <RiEdit2Fill />
                            </span>
                          </div>
                          <p>
                            {item.floor}, {item.building_name}, {item.landmark},{" "}
                            {item.full_address}
                          </p>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>

            {addresses.length >= 4 ? (
              ""
            ) : (
              <button
                onClick={() => {
                  handleAdd();
                }}
                className="btn text-success shadow-sm w-100 my-3"
              >
                <FaPlus className="mx-2" /> Add New Address
              </button>
            )}
            <Modal isOpen={isOpen} onClose={closeModal} />
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <h4 className="my-3 d-flex align-items-center text-secondary">
              {" "}
              <FaCcAmazonPay className="mx-2" /> Select Payment Method
            </h4>
            <div className="d-block my-3">
              <div className="custom-control custom-radio mb-2 ms-4">
                <input
                  id="RazorPay"
                  name="payment_method"
                  type="radio"
                  className="custom-control-input"
                  value="online"
                  required
                  onChange={handleChange}
                  // checked
                />
                <label className="custom-control-label ms-2">RazorPay</label>
              </div>
              <div className="custom-control custom-radio mb-2 ms-4">
                <input
                  id="cod"
                  name="payment_method"
                  type="radio"
                  className="custom-control-input"
                  value="cod"
                  required
                  onChange={handleChange}
                />
                <label className="custom-control-label ms-2">
                  Cash On Delivery
                </label>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 p-4">
            <div className="billing-address-carts">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted d-flex align-items-center">
                  {" "}
                  <MdShoppingCart className="mx-2" /> Your cart (
                  {cartData.cart_items && cartData.total_quantity})
                </span>
              </h4>
              <ul className="list-group mb-3">
                {cartData.cart_items &&
                  cartData.cart_items.map((item) => (
                    <li
                      className="list-group-item d-flex justify-content-between  align-items-center lh-condensed"
                      key={item.product.id}
                    >
                      <div className="d-flex gap-3">
                        <div className="mx-0">
                          <img
                            src={item.product.thumbnail}
                            alt={item.product.title}
                            title={item.product.title}
                            style={{ width: "35px", height: "35px" }}
                          />
                        </div>
                        <div className="mx-0">
                          <h6
                            className="my-0 product-cart-item-name"
                            title={item.product.title}
                          >
                            {item.product.title}
                          </h6>
                          <small className="text-muted">
                            {" "}
                            {item.product.net_weight}
                            <span>{item.product.unit.abbreviation}</span>
                          </small>
                        </div>
                      </div>
                      <div>
                        <span className="text-muted">
                          ₹ {item.product.offer_price}
                        </span>
                      </div>
                    </li>
                  ))}
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total (INR)</span>
                  <strong>
                    ₹ {cartData.cart_items && cartData.grand_total}
                  </strong>
                </li>
              </ul>
              <hr className="mb-4" />
              {loading ? (
                <ProcessingBtn />
              ) : (
                <button
                  className={`btn ${
                    chectOutData.shipping_address && chectOutData.payment_method
                      ? "active"
                      : "disabled"
                  }`}
                  type="submit"
                  onClick={() => createOrder(cartData.grand_total)}
                >
                  Proceed To Pay
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Modal for order confirmation */}
      {isOpenOrderConfirm && (
        <OrderConfirmModel onClose={() => setIsOpenOrderConfirm(false)} />
      )}
    </>
  );
};

export default CheckOut;

// // onClick={createOrder}

// // http://127.0.0.1:8000/product/payment/complete/
// // rzp_test_NqHIxmro5EHkHO
// // http://127.0.0.1:8000/product/payment/create/

// // const data = new FormData();
// // data.append("items", JSON.stringify(chectOutData.items));
// // data.append("shipping_address", chectOutData.shipping_address);
// // data.append("payment_method", chectOutData.payment_method);
