import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContextApi } from "../../ContextApi/AppProvider";

const ProductCategories = () => {
  const { ApiData } = useContext(ContextApi);

  return (
    <>
      <div className="container py-4">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="fea-sec-top pb-3">Product Categories</h3>
          </div>
          {/* <!-- card-row-start --> */}
          <div className="col-12 card-wrapper-cate d-flex">
            {/* <div className="card-wrapper-cate d-flex"> */}
            {ApiData.productCategories.map((items) => (
              <div className="card-wrap" key={items.id}>
                <Link className="link-primary" to={`/category/${items.slug}`}>
                  <div className="category-img">
                    <img src={items.image} alt={items.title} loading="lazy" />
                  </div>
                  <p>{items.title}</p>
                </Link>
              </div>
            ))}

            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCategories;
