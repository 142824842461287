import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContextApi } from "../../ContextApi/AppProvider";
import ProductNormalCart from "./ProductNormalCart";
const SeeAllProducts = () => {
  const { slug } = useParams();
  const { ApiData } = useContext(ContextApi);
  const [product, setProduct] = useState([]);
 
  useEffect(() => {
    setProduct(ApiData.allProducts.filter((item) => item.category.slug === slug));
  }, []);

  return (
    <>
      <div className="container">
        <div className="col-lg-12">
          <h3 className="m-3">{product?.[0]?.category?.title}</h3>
        </div>
        <div className="row">
          {product && product.length > 0 && (
            <ProductNormalCart value={product} />
          )}
        </div>
      </div>
    </>
  );
};

export default SeeAllProducts;
