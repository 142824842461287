import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { ContextApi } from "../../../components/ContextApi/AppProvider";
import { MdOutlineDoneAll, MdOutlineFileDownloadDone } from "react-icons/md";
import { BsBagCheck } from "react-icons/bs";
import { TbTruckDelivery } from "react-icons/tb";
import { GoArrowLeft } from "react-icons/go";

const OrderStatus = () => {
  const token = localStorage.getItem("token");
  const { domainApi } = useContext(ContextApi);
  const [address, setAddress] = useState({});
  const [step, setStep] = useState(1);
  const location = useLocation();
  const { data } = location.state;
  console.log(data);
  useEffect(() => {
    const fetchAddress = async (id) => {
      try {
        const res = await axios.get(
          `${domainApi}product/shipping-address/${id}/`,
          {
            headers: { Authorization: `TOKEN ${token}` },
          }
        );
        if (res.status === 200) {
          // console.log(res);
          setAddress(res.data);
        }
      } catch (error) {
        console.log(
          "there was a problem with fetch address in confirm order",
          error
        );
      }
    };

    fetchAddress(data.shipping_address);
  }, []);

  //   castumize date and tiem
  const isoString = data.created_at;
  const date = new Date(isoString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZone: "IST",
  };
  const formattedDateTime = date.toLocaleString("en-GB", options);

  const handleNext = () => {
    setStep((prev) => (prev <= 4 ? prev + 1 : 1));
  };

  const handlePrev = () => {
    setStep((prev) => (prev > 1 ? prev - 1 : 4));
  };

  return (
    <div className="container order-confirmation my-3">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 ps-lg-5 ps-0 thank-you-section d-flex align-items-center">
          <div>
          <Link to={`/user-dashboard/my-order`} className="fs-4">
          <GoArrowLeft />
          </Link>
            
            <h4>Order Status</h4>
            <div className="tracking-wrapper">
              <div className="tracking">
                <div id="progress" className={`progress-${step}`}>
                  <div className="empty-bar"></div>
                  <div className="color-bar"></div>
                  <ul>
                    <li className="bullet-1">
                      <div className="el">
                        <i className="bx bx-check">
                          <MdOutlineFileDownloadDone />
                        </i>
                      </div>
                      <div className="txt">Order Confirm</div>
                    </li>
                    <li className="bullet-2">
                      <div className="el">
                        <i className="bx bx-check">
                          <BsBagCheck />
                        </i>
                      </div>
                      <div className="txt">Order Picked</div>
                    </li>
                    <li className="bullet-3">
                      <div className="el">
                        <i className="bx bx-check">
                          <TbTruckDelivery />
                        </i>
                      </div>
                      <div className="txt">Out For Delivery</div>
                    </li>
                    <li className="bullet-4">
                      <div className="el">
                        <i className="bx bx-check">
                          <MdOutlineDoneAll />
                        </i>
                      </div>
                      <div className="txt">Delivered</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="controls">
                <div>
                  <button id="prev" onClick={handlePrev}>
                    <i className="bx bx-chevron-left"></i> Prev
                  </button>
                  <button id="next" onClick={handleNext}>
                    Next <i className="bx bx-chevron-right"></i>
                  </button>
                </div>
                <div>
                  <p>
                    Step: <span id="step">{step}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="order-confirm-billing-address my-4">
              <h4>Billing address</h4>
              <p>
                <strong>Name </strong>{"-"} {address.customer_name}
              </p>
              <p>
                <strong>Address </strong>{"-"} {address.full_address}
              </p>
              <p>
                <strong>Phone </strong>{"-"} {address.phone}
              </p>
            </div>
            <Link to={`/user-dashboard/my-order`}>
              <button className="btn btn-danger">Track Your Order</button>
            </Link>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 order-summary-section">
          <div className="order-summary">
            <h4>Order Summary</h4>
            <div className="d-flex justify-content-between mx-4 my-4">
              <p>
                Date <br /> <strong>{formattedDateTime}</strong>
              </p>
              <p>
                Order Number <br />
                <strong> {data.order_number}</strong>
              </p>
              <p>
                Payment Method <br />
                <strong> {data.payment_method}</strong>
              </p>
            </div>
            <div className="order-items">
              {data.items.map((item) => (
                <div className="item" key={item.product_id}>
                  <img src={item.product_image} alt={item.product_name} />
                  <div className="details">
                    <p>{item.product_name}</p>
                    <p>quantity: {item.quantity}</p>
                    {/* <p>{item.quantity}</p> */}
                    <p>{item.item_price}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="summary-totals">
              <p>
                <strong>Sub Total</strong>
                <span>₹{data.total_amount}</span>
              </p>
              <p>
                <strong>Shipping</strong>
                <span>$2.00</span>
              </p>
              <p>
                <strong>Tax</strong>
                <span>$5.00</span>
              </p>
              <p>
                <strong>Order Total</strong>
                <span>₹{data.total_amount}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;
