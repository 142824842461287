import React from 'react'
import Layout from './Layout';

const UserDashboard = () => {
  return (
    <>
  <Layout />
    </>
  )
}

export default UserDashboard; 