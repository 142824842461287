import React, { useContext, useEffect, useState } from "react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { ContextApi } from "../../../ContextApi/AppProvider";
import { Link } from "react-router-dom";
import ProductNormalCart from "../ProductNormalCart";

export const NewProductsCart = () => {

  const { ApiData } = useContext(ContextApi);
  const [product, setProduct] = useState([]);
useEffect(()=>{
  setProduct(ApiData.allProducts.map((item)=>item))
},[ApiData]);

//   //   castumize date and tiem
//   const isoString = product[0];
//   const date = new Date(isoString);
//   const options = {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit",
//     hour12: true,
//     timeZone: "IST",
//   };
//   const formattedDateTime = date.toLocaleString("en-GB", options);
// console.log(formattedDateTime);
// console.log(product);
// console.log(new Date)

  return (
    <section className="owl-crousel-section">
    <div className="row">
      <Link to={`/new-products`}>
        <div className="col-lg-12 px-3 ">
          <div className="releted_product d-flex align-items-center my-2 ">
            <h5 className="fs-5">New Products</h5>{" "}
            <span>
              <MdOutlineKeyboardDoubleArrowRight className="fs-4" />
            </span>
          </div>
        </div>
      </Link>
      <div className="col-lg-12 ">
       <div className="row">
        {product &&
          product.length > 0 &&
          product.splice(0, 3).map((item, index) => (
            <div className="col-lg-4 col-4" key={item.id}>
            <div className="owl-crousel-cart" >
              <Link to={`/product/${item.slug}`}>
                <div className="owl-crousel-cart-img">
                  <img
                    src={item.thumbnail}
                    alt={item.title}
                    title={item.title}
                  />
                </div>
                <div className="owl-crousel-cart-title my-2">
                  <p
                    className="owl-crousel-cart-name mb-1"
                    title={item.title}
                  >
                    {" "}
                    {item.title}
                  </p>
                  <p className="owl-crousel-cart-price">
                    ₹ {item.price}
                    <span className="ms-2 line_through_color text-decoration-line-through">
                      {item.offer_price}
                    </span>
                  </p>
                </div>
              </Link>
            </div>
            </div>
          ))}
          </div>
      </div>
    </div>
  </section>
  );
};

export const NewProductsPage = () => {
  const { ApiData } = useContext(ContextApi);
  const [product, setProduct] = useState([]);
useEffect(()=>{
  setProduct(ApiData.allProducts.splice(0,15).map((item)=>item))
},[ApiData]);
  return (
    <>
      <section className="about-home py-4">
        <div className="container">
          <div className="fs-4 text-center fw-bold mb-3">New Products</div>
          <div className="row">
            {product && product.length > 0 && (
              <ProductNormalCart value={product} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};
