import { PiPlus } from "react-icons/pi";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContextApi } from "../../../components/ContextApi/AppProvider";
import axios from "axios";
import { IoLocationOutline } from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri";
import MapContainer from "../../../components/pages/Payment/MapContainer";

const Address = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { domainApi} = useContext(ContextApi);
  const [addresses, setAddresses] = useState([]);
  const [updateAddress, setUpdateAddress] = useState([]);
  const token = localStorage.getItem("token");
  // const [addressToggle, setAddressToggle] = useState(false);
  // const [addressUpdateToggle, setAddressUpdateToggle] = useState(false);
  const [activeAddress, setActiveAddress] = useState([]);
  useEffect(() => {
    fetchAddress();
  }, [isOpen]);

  // fetch Address
  const fetchAddress = async () => {
    try {
      const res = await axios.get(`${domainApi}product/shipping-address/`, {
        headers: { Authorization: `TOKEN ${token}` },
      });
      if (res.request.status === 200) {
        // console.log(res);
        setAddresses(res.data);
      }
    } catch (error) {
      console.log("There Was An Problem With Address Api", error);
    }
  };

  const handleUpdate = (data) => {
    setUpdateAddress(data);
    openModal();
  };
  const handleAdd = () => {
    setUpdateAddress(null);
    openModal();
  };
  // Modal for location map START
  const Modal = ({ isOpen, onClose }) => {
    const overlayStyle = {
      display: isOpen ? "block" : "none",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 9999,
    };

    const modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      padding: "16px",
      borderRadius: "5px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
      zIndex: 10000,
    };

    return (
      <>
        <div style={overlayStyle} onClick={onClose}>
          <div
            className="row align-items-center address-model"
            style={modalStyle}
            onClick={(e) => e.stopPropagation()}
          >
            <span className="address-close-btn btn-close " onClick={onClose}>
              {" "}
            </span>

              <MapContainer close={onClose} updateAddress={updateAddress} />

          </div>
        </div>
      </>
    );
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="container">
      <h6>Manage Address</h6>
      <div className="row ">
        <div className="col-lg-4">
          {addresses.length >= 4 ? (
            ""
          ) : (
            <button
              className="btn btn-secondary"
              onClick={() => {
                  handleAdd()
                }}
            >
              <PiPlus /> Add New Address
            </button>
          )}
        </div>
        <Modal isOpen={isOpen} onClose={closeModal} />

        <div className="col-lg-9 my-4 border d-flex p-2">
          <div className="billing-address col-lg-12 ">
            <h4 className="my-3 d-flex align-items-center text-secondary">
              {" "}
              <FaHome className="mx-2" />
              Select Billing address
            </h4>
            <div className="over-height">
              <div className="select-address">
                {addresses && addresses.length > 0
                  ? addresses.reverse().map((item) => (
                      <div
                        className={`d-flex shadow-sm m-2 ${
                          activeAddress === item.id ? "active" : ""
                        }`}
                        key={item.id}
                        name="address"
                      >
                        <div className="mx-1 p-2">
                          <IoLocationOutline className="fs-3 m-2" />
                        </div>
                        <div className="">
                          <div className="d-flex align-items-baseline fs-5">
                          <h6>{item.address_type}</h6>{" "}
                            <span className="ms-2">
                              <RiEdit2Fill onClick={() => handleUpdate(item)} />{" "}
                            </span>
                          </div>
                          <p>
                            {item.floor}, {item.building_name}, {item.landmark},{" "}
                            {item.full_address}
                          </p>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Address;
