import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { TopDiscountProductsCart } from "./OwlCrousel/TopDiscountProducts";
import { TopRatedProductsCart } from "./OwlCrousel/TopRatedProducts";
import { NewProductsCart} from "./OwlCrousel/NewProducts";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { TrendingCarts } from "./OwlCrousel/TrendinProducts";

const OwlCrousel = () => {
  const owlOptions = {
    // autoplay: true,
    rewind: true,
    transition: 400,
    margin: 20,
    dots: false,
    responsiveClass: true,
    // autoplayTimeout: 3000,
    // smartSpeed: 2000,
    nav: true,
    nav: [<MdKeyboardArrowLeft />, <MdKeyboardArrowRight />],
    responsive: {
      0: { items: 1 },
      367: { items: 1 },
      575: { items: 1 },
      600: { items: 2 },
      768: { items: 2 },
      992: { items: 3 },
      1024: { items: 3 },
      1366: { items: 3 },
    },
  };

  return (
    <div className="container">
      <OwlCarousel className="owl-theme my-3" {...owlOptions} loop nav>
        <div className="item p-2">
          <TrendingCarts />
        </div>
        <div className="item p-2">
          <TopDiscountProductsCart />
        </div>
        {/* <div className="item p-2">
          <TopRatedProductsCart />
        </div> */}
        <div className="item p-2">
          <NewProductsCart />
        </div>
      </OwlCarousel>
    </div>
  );
};

export default OwlCrousel;



// if (loading) {
//   return <div>Progress ....</div>;
// }