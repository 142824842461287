import React, { useContext, useMemo, useState } from "react";
import { ContextApi } from "../../../ContextApi/AppProvider";
import axios from "axios";
import Swal from "sweetalert2";
import ProductNormalCart from "../ProductNormalCart";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

export const TrendingCarts = () => {
  const { domainApi } = useContext(ContextApi);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);

  useMemo(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${domainApi}product/trending/`);
        if (res.status === 200) {
          const shiftEndOutOfStock = (item) => {
            let Is_Available = [];
            let Not_Available = [];
            for (let i = 0; i < item.length; i++) {
              if (item[i].is_available) {
                Is_Available.push(item[i]);
              } else {
                Not_Available.push(item[i]);
              }
            }
            for (let i = 0; i < Not_Available.length; i++) {
              Is_Available.push(Not_Available[i]);
            }
            setLoading(false);
            return Is_Available;
          };
          setProduct(() => shiftEndOutOfStock(res.data));
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops, try again....",
          text: " Server",
        });
        console.log("there is a problem with Get Api", error);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <p>Processing...</p>;
  }

  return (
    <section className="owl-crousel-section">
      <div className="row">
        <Link to={`/trending`}>
          <div className="col-lg-12 px-3 ">
            <div className="releted_product d-flex align-items-center my-2 ">
              <h5 className="fs-5">Trending Products</h5>{" "}
              <span>
                <MdOutlineKeyboardDoubleArrowRight className="fs-4" />
              </span>
            </div>
          </div>
        </Link>
        <div className="col-lg-12 ">
          <div className="row">
            {product &&
              product.length > 0 &&
              product.splice(0, 3).map((item, index) => (
                <div className="col-lg-4 col-4" key={item.id}>
                  <div className="owl-crousel-cart">
                    <Link to={`/product/${item.slug}`}>
                      <div className="owl-crousel-cart-img">
                        <img
                          src={item.thumbnail}
                          alt={item.title}
                          title={item.title}
                        />
                      </div>
                      <div className="owl-crousel-cart-title my-2">
                        <p
                          className="owl-crousel-cart-name mb-1"
                          title={item.title}
                        >
                          {" "}
                          {item.title}
                        </p>
                        <p className="owl-crousel-cart-price">
                          ₹ {item.price}
                          <span className="ms-2 line_through_color text-decoration-line-through">
                            {item.offer_price}
                          </span>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export const TrendingProductsPage = () => {
  const { domainApi } = useContext(ContextApi);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);

  useMemo(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${domainApi}product/trending/`);
        if (res.status === 200) {
          const shiftEndOutOfStock = (item) => {
            let Is_Available = [];
            let Not_Available = [];
            for (let i = 0; i < item.length; i++) {
              if (item[i].is_available) {
                Is_Available.push(item[i]);
              } else {
                Not_Available.push(item[i]);
              }
            }
            for (let i = 0; i < Not_Available.length; i++) {
              Is_Available.push(Not_Available[i]);
            }
            setLoading(false);
            return Is_Available;
          };
          setProduct(() => shiftEndOutOfStock(res.data));
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops, try again....",
          text: " Server",
        });
        console.log("there is a problem with Get Api", error);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <p>Processing...</p>;
  }

  return (
    <>
      <section className="about-home py-4">
        <div className="container">
          <div className="fs-4 text-center fw-bold mb-3">Trending Items</div>
          <div className="row">
            {product && product.length > 0 && (
              <ProductNormalCart value={product} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};
