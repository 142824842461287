import React, { useEffect } from "react";
import Footer from "../pages/commanfiles/Footer";
import { Outlet } from "react-router-dom";
import Header from "../pages/commanfiles/Header";

const Layout = () => {

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
