// import React, { useContext, useEffect, useState } from "react";
// import axios from "axios";
// import { ContextApi } from "../../../components/ContextApi/AppProvider";
// import OrderStatus from "./OrderStatus";
// import { Accordion } from "react-bootstrap";
// import OrderSummery from "./OrderSummery";

// const MyOrders = () => {
//   const { domainApi } = useContext(ContextApi);
//   const token = localStorage.getItem("token");
//   const [orders, setOrders] = useState([]);
//   useEffect(() => {
//     fetchData();
//   }, []);
//   const fetchData = async () => {
//     try {
//       const res = await axios.get(`${domainApi}product/customer/orders/`, {
//         headers: { Authorization: `TOKEN ${token}` },
//       });
//       if (res.status === 200) {
//         console.log(res.data);
//         setOrders(res.data);
//       }
//     } catch (error) {
//       console.log("there was a Problem With MyOrder Api", error);
//     }
//   };
// console.log(orders)
//   return (
//     <>
//       <section className="order_develvered_section py-3">
//         <div className="container">
//           <div className="row">
//             {orders && orders.length > 0
//               ? orders.map((item) => (
//                   <Accordion key={item.id}>
//                     <Accordion.Item eventKey={item.id}>
//                       <Accordion.Header>
//                         <div className="col-lg-6 ">
//                           {/* {item.items.map((item) => ( */}
//                             <div className="order_detail d-flex" >
//                               <img
//                                 src={item.items[0].product_image}
//                                 alt={item.items[0].product_name}
//                               />
//                               <div className="order_bottom_detail m-2">
//                                 <p>{item.items[0].product_name}</p>
//                                 <small>quantity : {item.items[0].quantity}</small>
//                               </div>
//                             </div>
//                           {/* ))} */}
//                         </div>
//                         <div className="col-lg-2">
//                           <div className="order_price">
//                             <p>₹{item.total_amount}</p>
//                             <p>{item.order_status}</p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="delevery_time_order">
//                             <p>
//                               <span className="round_color"></span>Delivered on
//                               Apr 29
//                             </p>
//                             <small>Your item has been delivered</small>
//                             <p>Rate & Review Products</p>
//                           </div>
//                         </div>
//                       </Accordion.Header>
//                       <Accordion.Body>
//                         <OrderStatus data={item} />
//                         <OrderSummery/>
//                       </Accordion.Body>
//                     </Accordion.Item>
//                   </Accordion>
//                 ))
//               : "No More Items In Your Order"}
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default MyOrders;

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ContextApi } from "../../../components/ContextApi/AppProvider";
import OrderStatus from "./OrderStatus";
import { Accordion } from "react-bootstrap";
import OrderSummery from "./OrderSummery";
import { useNavigate } from "react-router-dom";

const MyOrders = () => {
  const { domainApi } = useContext(ContextApi);
  const token = localStorage.getItem("token");
  const [orders, setOrders] = useState();
  const navigate = useNavigate()
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const res = await axios.get(`${domainApi}product/customer/orders/`, {
        headers: { Authorization: `TOKEN ${token}` },
      });
      if (res.status === 200) {
        console.log(res.data);
        setOrders(res.data);
      }
    } catch (error) {
      console.log("there was a Problem With MyOrder Api", error);
    }
  };

  const viewOrderSummery = (data) =>{
    navigate(`/user-dashboard/order-summery`,{ state : {data}})
  }
  // console.log(orders);
  return (
    <>
      <section className="order_develvered_section py-3 ">
        <div className="container">
            {orders && orders.length > 0
              ? orders.reverse().map((item) => (
          <div className="row srowsd align-items-center" key={item.id} onClick={()=>viewOrderSummery(item)} >
                    <div className="col-lg-5 col-6  my-3">
                      {/* {item.items.map((item) => ( */}
                      <div className="order_detail align-items-center gap-3 d-flex">
                        <img
                          src={item.items[0].product_image}
                          alt={item.items[0].product_name}
                        />
                        <div className="order_bottom_detail m-2">
                          <p>{item.items[0].product_name}</p>
                          <small>quantity : {item.items[0].quantity}</small>
                        </div>
                      </div>
                      {/* ))} */}
                    </div>
                    <div className="col-lg-3 col-6 my-3">
                      <div className="order_price">
                        <p>₹{item.total_amount}</p>
                        <p>{item.order_status}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-12 my-3">
                      <div className="delevery_time_order">
                        <p>
                          <span className="round_color"></span>Delivered on Apr
                          29
                        </p>
                      </div>
                    </div>
                    {/* <hr className="flex-direction-coloum"/> */}
                  </div>
                ))
              : "No More Items In Your Order"}
          </div>

      </section>
    </>
  );
};

export default MyOrders;
