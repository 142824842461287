import React, { useContext, useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ContextApi } from "../../ContextApi/AppProvider";
import ProductCart from "./ProductCart";

const ColdDrinksAndjuices = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [Loading, setLoading] = useState(true);
  const { ApiData } = useContext(ContextApi);

  const category_id = category.length > 0 ? category[4].id : null;

  useEffect(() => {
    setAllProducts(ApiData.allProducts);
    setCategory(ApiData.productCategories);
  }, [ApiData]);

  useEffect(() => {
    if (category_id !== null) {
      const filteredProducts = allProducts.filter(
        (item) => item.category.id === category_id
      );
      setProducts(filteredProducts);
      setLoading(false);
    } else {
      setProducts(allProducts);
      setLoading(false);
    }
  }, [allProducts, category_id]);

  if (Loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {products.length > 0 && <ProductCart value={products} />}
    </>
  );
};

export default ColdDrinksAndjuices;
