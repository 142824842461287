import React, { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ContextApi } from "../ContextApi/AppProvider";

const Faqs = () => {
  const {ApiData} = useContext(ContextApi);
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 my-4">
            <h3 className="my-3"> FAQs</h3>{" "}
            {ApiData.faqs && ApiData.faqs.length > 0 ?  ApiData.faqs.map((item)=>
            <Accordion key={item.id}>
              <Accordion.Item className="my-2" eventKey="0">
                <Accordion.Header className="main-accordion-button">{item.category.title}</Accordion.Header>
                <Accordion.Body>
                  <Accordion>
                    <Accordion.Item className="my-1" eventKey="0">
                      <Accordion.Header className="sub-accordion-button">{item.question}</Accordion.Header>
                      <Accordion.Body  dangerouslySetInnerHTML={{
                              __html: item.answer,
                            }}>
                     
                       
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
            ) : ('NO FAQS')}
          </div>{" "}
        </div>{" "}
      </div>
    </section>
  );
};

export default Faqs;
