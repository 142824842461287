import React from "react";
import Sidebar from "./Sidebar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Footer from "../../components/pages/commanfiles/Footer";
import Header from "../../components/pages/commanfiles/Header";

const Layout = () => {

  return (
    <>
      <Header />
      <Sidebar />
      <Footer />
    </>
  );
};

export default Layout;
