import React, { useContext } from "react";
import { CartContextApi } from "../../ContextApi/CartContext";
import { Link } from "react-router-dom";
import ProductCartButton from "../homepages/ProductCartButton";
import axios from "axios";
import { ContextApi } from "../../ContextApi/AppProvider";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import NoDataFound from "../../ScrollButton/NoDataFound";

const ShowCarts = () => {
  const { domainApi } = useContext(ContextApi);
  const { cartData, localCartData, updateLocalStorageShowCart } =
    useContext(CartContextApi);
  const { toggle, productQuantity, handleToggle, handleQuantityChange } =
    useContext(CartContextApi);
  const token = localStorage.getItem("token");

  const handleRemove = async (id, product_id) => {
    try {
      const res = await axios.delete(`${domainApi}product/cart/delete/${id}/`, {
        headers: { Authorization: `TOKEN ${token}` },
      });
      if (res.status === 204) {
        updateLocalStorageShowCart(product_id);
      }
    } catch (error) {
      console.log("There was a problem with cart delete API", error);
    }
  };

  // Total discount
  const totalDiscount =
    cartData?.cart_items?.length > 0 && cartData.cart_items.reduce(getSum, 0);
  function getSum(total, item) {
    return (
      total +
      (item.product.price * item.quantity * parseInt(item.product.discount)) /
        100
    );
  }

  const totalDiscountLocal =
    localCartData?.items?.length > 0 &&
    localCartData.quantities.reduce((sum, quantity) => {
      const matchItem = localCartData.items.find(
        (item) => quantity.id === item.id
      );
      if (matchItem) {
        return sum + parseInt(matchItem.price) * quantity.quantity;
      }
      return sum;
    }, 0);

  const basePrice = token ? cartData?.grand_total : localCartData?.grand_total;
  const smallCartFee = 5;
  const handlingCharge = 15;
  const deliveryFee = 5.4;
  // Calculate total price including additional charges
  const totalPayPrice = basePrice + smallCartFee + handlingCharge + deliveryFee;

  if(cartData?.cart_items?.length === 0){
    return <NoDataFound/>
  }

  return (
    <>
      {token ? (
        <section className="cart-page py-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cart-heading ">
                  <p>
                    (
                    {cartData.cart_items && cartData.cart_items.length > 0
                      ? cartData.cart_items.length
                      : "0"}{" "}
                    items)
                  </p>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="left-cart">
                  {cartData?.cart_items?.length > 0 ? (
                    cartData.cart_items.map((item) => (
                      <div className="left-bar-card" key={item.product.id}>
                        <div className="menu-crt">
                          <img
                            src={item.product.thumbnail}
                            alt={item.product.title}
                            title={item.product.title}
                          />
                          <div className="name-crt-bottom ms-3">
                            <div className="d-flex justify-content-between fs-6">
                              <Link to={`/product/${item.product.slug}`}>
                                <h6
                                  className="product-cart-item-name"
                                  title={item.product.title}
                                >
                                  {item.product.title}
                                </h6>
                              </Link>
                              <span
                                className="btn-close"
                                onClick={() =>
                                  handleRemove(item.id, item.product.id)
                                }
                              ></span>
                            </div>
                            <small>
                              {item.product.net_weight}{" "}
                              <span>{item.product.unit.abbreviation}</span>
                            </small>
                            <div className="price">
                              ₹
                              {item.product.discount &&
                              Math.floor(item.product.discount) <= 0 ? (
                                <span className="price-without-discount">
                                  {item.product.offer_price}
                                </span>
                              ) : (
                                <>
                                  <span className="fs-6 text-decoration-line-through price-and-discount">
                                    {item.product.price}
                                  </span>
                                  {item.product.offer_price}
                                  <span className="discount-cart">
                                    {Math.floor(item.product.discount)}% off
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <ProductCartButton
                          className="product-details-crt-plus"
                          value={item.product}
                          productQuantity={productQuantity}
                          toggle={toggle}
                          handleQuantityChange={handleQuantityChange}
                          handleToggle={handleToggle}
                        />
                      </div>
                    ))
                  ) : (
                    <p>No items in the cart</p>
                  )}
                </div>
                  <Link className="btn bg-secondary-btn-continue text-white my-4" to={`/`}> <FaRegArrowAltCircleLeft /> Continue Shopping</Link>
              </div>
              {cartData?.cart_items?.length > 0 ? (
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="select-item">
                    <div className="total-item-crt">
                      <div className="item-total">
                        <h6>Total Item Price</h6>
                        <p>
                          Total Discount 
                        </p>
                        <p>
                          Small Cart Fee 
                        </p>
                        <p>
                          Handling Charge 
                        </p>
                        <p>
                          Delivery Fee 
                        </p>
                        <h6 className="mt-3">To Pay</h6>
                      </div>
                      <div className="price-size">
                        <h6>₹{cartData?.grand_total || 0}</h6>
                        <p className="text-success">
                          - ₹{totalDiscount.toFixed(2)}
                        </p>
                        <p>+ ₹{smallCartFee.toFixed(2)}</p>
                        <p>+ ₹{handlingCharge.toFixed(2)}</p>
                        <p>+ ₹{deliveryFee.toFixed(2)}</p>
                        <h6 className="mt-3">₹{totalPayPrice.toFixed(2)}</h6>
                      </div>
                    </div>
                    <div>
                      <Link className="btn btn-danger paynow" to={`/checkout`}>
                        Proceed To CheckOut
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <p>No Record Found</p>
              )}
            </div>
          </div>
        </section>
      ) : (
        <section className="cart-page py-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cart-heading">
                  <p>
                    (
                    {localCartData.items && localCartData.items.length > 0
                      ? localCartData.items.length
                      : "0"}{" "}
                    items)
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="left-cart">
                  {localCartData?.items?.length > 0 ? (
                    localCartData.items.map((item) => (
                      <div className="left-bar-card" key={item.id}>
                        <div className="menu-crt">
                          <img
                            src={item.thumbnail}
                            alt={item.title}
                            title={item.title}
                          />
                          <div className="name-crt-bottom ms-3">
                            <Link to={`/product/${item.slug}`}>
                              <h6
                                className="product-cart-item-name"
                                title={item.title}
                              >
                                {item.title}
                              </h6>
                            </Link>
                            <small>
                              {item.net_weight}{" "}
                              <span>{item.unit.abbreviation}</span>
                            </small>
                            <p>₹{item.offer_price}</p>
                          </div>
                        </div>
                        <ProductCartButton
                          className="product-details-crt-plus"
                          value={item}
                          productQuantity={productQuantity}
                          toggle={toggle}
                          handleQuantityChange={handleQuantityChange}
                          handleToggle={handleToggle}
                        />
                      </div>
                    ))
                  ) : (
                    <p>No items in the cart</p>
                  )}
                </div>
                <Link className="btn bg-secondary-btn-continue text-white my-4" to={`/`}> <FaRegArrowAltCircleLeft /> Continue Shopping</Link>
              </div>
              {localCartData?.items?.length > 0 ? (
                <div className="col-lg-5">
                  <div className="select-item">
                    <div className="total-item-crt">
                      <div className="item-total">
                        <h6>item total</h6>
                        <p>
                          Total Discount <i className="fa-solid fa-info"></i>
                        </p>
                        <p>
                          Small Cart Fee <i className="fa-solid fa-info"></i>
                        </p>
                        <p>
                          Handling Charge <i className="fa-solid fa-info"></i>
                        </p>
                        <p>
                          Delivery Fee <i className="fa-solid fa-info"></i>
                        </p>
                        <h6 className="mt-3">To Pay</h6>
                      </div>
                      <div className="price-size">
                        <h6>₹{localCartData?.grand_total || 0}</h6>
                        <p className="text-success">
                          - ₹
                          {(
                            totalDiscountLocal - localCartData.grand_total
                          ).toFixed(2)}
                        </p>

                        <p>₹{smallCartFee.toFixed(2)}</p>
                        <p>₹{handlingCharge.toFixed(2)}</p>
                        <p>₹{deliveryFee.toFixed(2)}</p>
                        <h6 className="mt-3">₹{totalPayPrice.toFixed(2)}</h6>
                      </div>
                    </div>
                    <div>
                      <Link className="btn btn-danger paynow" to={`/login`}>
                        Proceed To CheckOut
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <p>No Record Found</p>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ShowCarts;
