import React, { useContext, useEffect, useState } from "react";
import { ContextApi } from "../../ContextApi/AppProvider";
import { useParams } from "react-router-dom";
import axios from "axios";
import NoDataFound from "../../ScrollButton/NoDataFound";
// import LoadingPage from "../../ScrollButton/LoadingPage";
import ProductNormalCart from "./ProductNormalCart";

const CategoryDetails = () => {
  const { domainApi } = useContext(ContextApi);
  const { slug } = useParams();
  const { ApiData } = useContext(ContextApi);
  const [subCategory, setSubCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [activeSubCategory, setActiveSubCategory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [slug]);

  useEffect(() => {
    if (subCategory.length > 0) {
      const firstSubCategory = subCategory[0];
      setActiveSubCategory(firstSubCategory.id);
      handleFilter(firstSubCategory.title, firstSubCategory.id);
    } else {
      setProduct([]);
    }
  }, [subCategory]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${domainApi}product/category/${slug}/`);
      console.log("subcategory slug",res)
      if (res.status === 200) {
        setSubCategory(res.data.subcategories);
      }
    } catch (error) {
      console.log("There is a problem with the GET API", error);
      setSubCategory([]);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = (title, id) => {
    setActiveSubCategory(id);
    const filterProduct = ApiData.allProducts.filter(
      (item) => item.subcategory.title === title
    );
    setProduct(filterProduct);
  };


  const toggleActive = (id) => {
    setActiveSubCategory(id);
  };

  if (loading) {
    return (
      <div>
      <NoDataFound />
        {/* <LoadingPage /> */}
      </div>
    ); // Show a loading state while fetching data
  }

  return (
    <>
      {subCategory.length === 0 && product.length === 0 ? (
        <NoDataFound />
      ) : (
        <section className="about-home py-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-3 p-0">
                <div className="categories-table__row">
                  <div className="subcategory-sidebar">
                    {subCategory.map((item) => (
                      <div
                        className={`category-left-list ${
                          activeSubCategory === item.id ? "active" : ""
                        }`}
                        onClick={() => {
                          toggleActive(item.id);
                          handleFilter(item.title, item.id);
                        }}
                        key={item.id}
                      >
                        <div className="subcategory-left-list-img">
                          <img
                            src={item.image}
                            alt={item.title}
                            title={item.title}
                            loading="lazy"
                          />
                        </div>
                        <p className="mb-0">{item.title}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Product listing */}
              <div className="cd-subcategory-item col-lg-9 col-md-9 col-9">
                <div className="row gap-3">
                  {product && product.length > 0 && (
                    <ProductNormalCart value={product} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CategoryDetails;
