import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { ContextApi } from "../ContextApi/AppProvider";

const AboutUs = () => {
  const { ApiData } = useContext(ContextApi);
  const [data, setData] = useState(ApiData.aboutUs);

  return (
    <>
      {/* Bradcrum section start */}
      <div className="container-fluid p-0 about-bradcrum">
        <div className="Contact_main_heading">
          <div className="contact_head">
            <div className="bread">
              <h3> About Us</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/about-us">About</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* Bradcrum section End */}
      {/* <!-- About Us Section Start--> */}
      <section id="section2" className="about_section_main_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-12" key={data.id}>
              <div className="about_head">
                <h3>{data.title}</h3>
                <p
                  className="about-conta mt-3 mb-0"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- About Section End--> */}
    </>
  );
};

export default AboutUs;
