import React, { useContext, useState } from "react";
import img from "../../../../assets/images/kurkure2.jfif";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { ContextApi } from "../../../ContextApi/AppProvider";
import { Link } from "react-router-dom";
import ProductNormalCart from "../ProductNormalCart";

export const TopRatedProductsCart = () => {
  return (
    <section className="owl-crousel-section shadow">
      <Link to={`/top-rated`}>
        <div className="row">
          <div className="col-lg-12 px-5 ">
            <div className="d-flex justify-content-between align-items-center my-2">
              <h5 className="fs-5">Top-Rated Items</h5>{" "}
              <span>
                <MdOutlineKeyboardDoubleArrowRight className="fs-4" />
              </span>
            </div>
          </div>
          <div className="col-lg-12 d-flex owl-crousel-cart-main ">
            {[1, 2, 3].map((item, index) => (
              <div className="owl-crousel-cart" key={index}>
                <div className="owl-crousel-cart-img">
                  <img src={img} alt={`teanding product`} />
                </div>
                <div className="owl-crousel-cart-title my-2">
                  <p className="owl-crousel-cart-name mb-1">
                    {" "}
                    Lays Product Title titi
                  </p>
                  <p className="owl-crousel-cart-price">
                    ₹
                    <span className="fs-6 text-decoration-line-through">
                      26.00{" "}
                    </span>{" "}
                    20.00
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Link>
    </section>
  );
};

export const TopRatedProductsPage = () => {
  const { ApiData } = useContext(ContextApi);
  const [product, setProduct] = useState(ApiData.allProducts);
  return (
    <>
      <section className="about-home py-4">
        <div className="container">
          <div className="fs-4 text-center fw-bold mb-3">Top Rated Items</div>
          <div className="row">
            {product && product.length > 0 && (
              <ProductNormalCart value={product} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};
