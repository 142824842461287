import "./assets/css/style.css";
import "../src/index.css";
import "./assets/js/main";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Fragment } from "react";

import Signup from "./components/pages/SignUpAndLogIn/Signup";
import OtpVerify from "./components/pages/SignUpAndLogIn/OtpVerify";
import LogIn from "./components/pages/SignUpAndLogIn/LogIn";
import ResetPassword from "./components/pages/SignUpAndLogIn/ResetPassword";
import Layout from "./components/Layout/Layout";
import HomePage from "./components/pages/HomePage";
import ShowCarts from "./components/pages/ShowCarts/ShowCarts";
import SearchProducts from "./components/pages/SearchProducts";
import ProductDetails from "./components/pages/homepages/ProductDetails";
import CategoryDetails from "./components/pages/homepages/CategoryDetails";
import CheckOut from "./components/pages/Payment/CheckOut";
import AboutUs from "./components/pages/AboutUs";
import ContactUs from "./components/pages/ContactUs";
import Feedback from "./components/pages/Feedback";
import PrivacyAndPolicy from "./components/pages/Privacy&Policy";
import TermsAndConditions from "./components/pages/Terms&Conditions";
import ScrollButton from "./components/ScrollButton/ScrollButton";

import UserDashboard from "./dashboards/UserDashboard/UserDashboard";
import MyOrders from "./dashboards/UserDashboard/components/MyOrders";
import Profile from "./dashboards/UserDashboard/components/Profile";
import Address from "./dashboards/UserDashboard/components/Address";
import Modal from 'react-modal';
import ScrollToTop from "./components/ScrollButton/ScrollToTop";
import { TopDiscountProductsPage } from "./components/pages/homepages/OwlCrousel/TopDiscountProducts";
import { TopRatedProductsPage } from "./components/pages/homepages/OwlCrousel/TopRatedProducts";
import { NewProductsPage} from "./components/pages/homepages/OwlCrousel/NewProducts";
import { TrendingProductsPage } from "./components/pages/homepages/OwlCrousel/TrendinProducts";
import SeeAllProducts from "./components/pages/homepages/SeeAllProducts";
import OrderStatus from "./dashboards/UserDashboard/components/OrderStatus";
import PageNotFound from "./components/ScrollButton/PageNotFound";
import NoDataFound from "./components/ScrollButton/NoDataFound";
import Faqs from "./components/pages/Faqs";
import ProcessingBtn from "./components/ScrollButton/ProcessingBtn";
import WishlistCart from "./components/pages/ShowCarts/WishlistCart";

Modal.setAppElement('#root');

const App = () => {

  return (
    <>
      <Router>
      <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Layout />}>
          <Route path="/*" element={<PageNotFound />} />
            <Route index element={<HomePage />} />
            {/* <Route path="/user/:role" element={<Layout />} /> */}
            <Route path="/signup" element={<Signup />} />
            <Route path="/verify-otp" element={<OtpVerify />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/top-discount" element={<TopDiscountProductsPage />} />
            <Route path="/top-rated" element={<TopRatedProductsPage />} />
            <Route path="/new-products" element={<NewProductsPage />} />
            <Route path="/trending" element={<TrendingProductsPage />} />
            <Route path="/search-products" element={<SearchProducts />} />
            <Route path="/product/:slug" element={<ProductDetails />} />
            <Route path="/category/:slug" element={<CategoryDetails />} />
            <Route path="/all-products/:slug" element={<SeeAllProducts />} />
            {/* <Route path="/wishlist" element={<WishlistCart />} /> */}
            <Route path="/show-carts" element={<ShowCarts />} />
            <Route path="/checkout" element={<CheckOut />} />
            <Route path="/no-data-found" element={<NoDataFound />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
            <Route path="/terms-condition" element={<TermsAndConditions />} />
            <Route path="/faqs" element={<Faqs />} />

            <Route path="/user-dashboard/order-summery" element={<OrderStatus />} />
          </Route>

          <Route path="/user-dashboard" element={<UserDashboard />}>
            <Route path="/user-dashboard/profile" element={<Profile />} />
            <Route path="/user-dashboard/address" element={<Address />} />
            <Route path="/user-dashboard/my-order" element={<MyOrders />} />


          </Route>
        </Routes>
      </Router>

      <Fragment>
        <ScrollButton />
      </Fragment>
    </>
  );
};

export default App;
