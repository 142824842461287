import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CartContextApi } from "../../ContextApi/CartContext";

const AddToCart = () => {
  const { cartData, localCartData } = useContext(CartContextApi);
  const token = localStorage.getItem("token");
  return (
    <>
      <span className="cart-wrap">
        <Link to="/show-carts">
          <i className="fa-solid fa-cart-shopping"></i>
          <span className="cart_box" id="number">
            {!token
              ? localCartData
                ? localCartData.total_quantity
                : 0
              : cartData
              ? cartData.total_quantity
              : 0}
          </span>
        </Link>
        ₹
        {!token
          ? localCartData
            ? localCartData.grand_total
            : 0
          : cartData
          ? cartData.grand_total
          : 0}
      </span>
    </>
  );
};

export default AddToCart;
