import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import { ContextApi } from "./AppProvider";

export const CartContextApi = createContext();

export const CartProvider = ({ children }) => {
  const { ApiData, domainApi } = useContext(ContextApi);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [cartData, setCartData] = useState({
    total_quantity: 0,
    grand_total: 0,
    items: [],
  });

  const [localCartData, setLocalCartData] = useState({
    total_quantity: 0,
    grand_total: 0,
    quantities: 0,
    items: [],
  });
  const [toggle, setToggle] = useState({});
  const [productQuantity, setProductQuantity] = useState({
    cart_items: [],
  });

  const updateToken = useCallback((newToken) => {
    setToken(newToken);
    fetchCartData();
  }, []);

  useEffect(() => {
    if (token) {
      fetchCartData();
    }
    loadLocalStorageData();
  }, [token]);

  useEffect(() => {
    if (token && productQuantity.cart_items.length > 0) {
      updateCart(productQuantity.cart_items);
    }
  }, [token]);

  useEffect(() => {
    localStorage.setItem("toggle", JSON.stringify(toggle));
    localStorage.setItem("productQuantity", JSON.stringify(productQuantity));
    updateLocalCartData();
  }, [toggle, productQuantity]);

  const fetchCartData = async () => {
    if (token) {
      try {
        const res = await axios.get(`${domainApi}product/cart/`, {
          headers: { Authorization: ` TOKEN ${token}` },
        });
        if (res.status === 200) {
          setCartData(res.data);

          const updatedToggle = res.data.cart_items.reduce((acc, item) => {
            acc[item.product.id] = true;
            return acc;
          }, {});

          localStorage.setItem("toggle", JSON.stringify(updatedToggle));

          const updatedProductQuantity = {
            cart_items: res.data.cart_items.map((item) => ({
              product_id: item.product.id,
              quantity: item.quantity,
            })),
          };
          localStorage.setItem(
            "productQuantity",
            JSON.stringify(updatedProductQuantity)
          );

          setToggle(updatedToggle);
          setProductQuantity(updatedProductQuantity);
        }
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    }
  };

  const loadLocalStorageData = () => {
    const storedToggle = localStorage.getItem("toggle");
    const storedProductQuantity = localStorage.getItem("productQuantity");

    if (storedToggle) {
      setToggle(JSON.parse(storedToggle));
    }
    if (storedProductQuantity) {
      setProductQuantity(JSON.parse(storedProductQuantity));
    }
  };

  const updateLocalCartData = () => {
    const itemsArray = productQuantity.cart_items.map(
      ({ product_id, quantity }) => ({
        id: product_id,
        quantity,
      })
    );
    const localCartItems = itemsArray.filter((item) => item.quantity > 0);
    const matchedProducts = ApiData.allProducts.filter((product) =>
      localCartItems.some((cartItem) => cartItem.id === product.id)
    );
    const totalQuantity = localCartItems.reduce(
      (accum, item) => accum + item.quantity,
      0
    );
    const totalAmount = parseFloat(
      localCartItems
        .reduce((accum, cartItem) => {
          const product = ApiData.allProducts.find(
            (product) => product.id === cartItem.id
          );
          return product
            ? accum + parseFloat(product.offer_price) * cartItem.quantity
            : accum;
        }, 0)
        .toFixed(2)
    );

    setLocalCartData({
      total_quantity: totalQuantity,
      grand_total: totalAmount,
      quantities: itemsArray,
      items: matchedProducts,
    });
  };

  const updateCart = async (cartItems) => {
    if (token && cartItems && cartItems.length > 0) {
      try {
        await axios.post(
          `${domainApi}product/cart/add/`,
          { cart_items: cartItems },
          { headers: { Authorization: `TOKEN ${token}` } }
        );
        fetchCartData();
      } catch (error) {
        console.error("Error updating cart:", error);
      }
    }
  };

  const deleteCart = async (id) => {
    const deleteProduct = cartData.cart_items.find(
      (item) => item.product.id === id
    );
    if (deleteProduct) {
      const deleteId = deleteProduct.id;
      try {
        const res = await axios.delete(
          `${domainApi}product/cart/delete/${deleteId}/`,
          {
            headers: { Authorization: `TOKEN ${token}` },
          }
        );
        if (res.status === 204) {
          fetchCartData();
        }
      } catch (error) {
        console.log("There was a problem with your delete API", error);
      }
    }
  };

  const handleQuantityChange = (id, increment = true) => {
    setProductQuantity((prevQuantities) => {
      const existingItemIndex = prevQuantities.cart_items.findIndex(
        (item) => item.product_id === id
      );
      let updatedCartItems = [...prevQuantities.cart_items];

      if (existingItemIndex >= 0) {
        const existingItem = updatedCartItems[existingItemIndex];
        const newQuantity = increment
          ? existingItem.quantity + 1
          : Math.max(existingItem.quantity - 1, 0);

        if (newQuantity === 0) {
          updatedCartItems.splice(existingItemIndex, 1);
          if (newQuantity === 0 && token) {
            deleteCart(id);
          }
          setToggle((prevToggle) => ({
            ...prevToggle,
            [id]: false,
          }));
        } else {
          updatedCartItems[existingItemIndex] = {
            ...existingItem,
            quantity: newQuantity,
          };
          setToggle((prevToggle) => ({
            ...prevToggle,
            [id]: true,
          }));
        }
      } else if (increment) {
        updatedCartItems.push({ product_id: id, quantity: 1 });
        setToggle((prevToggle) => ({
          ...prevToggle,
          [id]: true,
        }));
      }

      const newProductQuantity = { cart_items: updatedCartItems };
      if (token) {
        updateCart(newProductQuantity.cart_items);
      }
      updateLocalCartData();
      return newProductQuantity;
    });
  };

  const handleToggle = (id) => {
    setToggle((prevToggle) => {
      const updatedToggle = { ...prevToggle };
      if (updatedToggle[id]) {
        delete updatedToggle[id];
      } else {
        updatedToggle[id] = true;
      }
      return updatedToggle;
    });

    handleQuantityChange(id, !toggle[id]);
  };

  const updateLocalStorageShowCart = (product_id) => {
    let toggle = JSON.parse(localStorage.getItem("toggle")) || {};
    if (toggle.hasOwnProperty(product_id)) {
      delete toggle[product_id];
    } 

    let cartData = JSON.parse(localStorage.getItem("productQuantity")) || {
      cart_items: [],
    };
    let updatedCartItems = cartData.cart_items.filter(
      (item) => item.product_id !== product_id
    );

    localStorage.setItem("toggle", JSON.stringify(toggle));
    localStorage.setItem(
      "productQuantity",
      JSON.stringify({ cart_items: updatedCartItems })
    );
    fetchCartData();
    loadLocalStorageData();
    updateLocalCartData();
  };

  const updateLocalStorageOrderConfirm = () => {
    localStorage.removeItem("toggle");
    localStorage.removeItem("productQuantity");
    fetchCartData();
    loadLocalStorageData();
    updateLocalCartData();
  };

  const emptyLocalStorageLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("productQuantity");
    localStorage.removeItem("toggle");
    localStorage.removeItem("userid");
    setToken(null);
    setCartData({
      total_quantity: 0,
      grand_total: 0,
      items: [],
    });
    setLocalCartData({
      total_quantity: 0,
      grand_total: 0,
      quantities: 0,
      items: [],
    });
    setToggle({});
    setProductQuantity({
      cart_items: [],
    });
  };

  const ContextValue = useMemo(
    () => ({
      cartData,
      toggle,
      productQuantity,
      localCartData,
      updateToken,
      handleToggle,
      handleQuantityChange,
      updateLocalStorageShowCart,
      updateLocalStorageOrderConfirm,
      emptyLocalStorageLogOut,
    }),
    [
      cartData,
      toggle,
      productQuantity,
      localCartData,
      updateLocalStorageShowCart,
      updateLocalStorageOrderConfirm,
      emptyLocalStorageLogOut,
    ]
  );

  return (
    <CartContextApi.Provider value={ContextValue}>
      {children}
    </CartContextApi.Provider>
  );
};
