import Banner from "./homepages/Banner";
import ProductCategories from "./homepages/ProductCategories";
import ColdDrinksAndjuices from "./homepages//ColdDrinksAndjuices";
import SnacksAndMunchies from "./homepages//SnacksAndMunchies";
import Grocery from "./homepages/Grocery";
import FruitsAndVegetables from "./homepages/FruitsAndVegetables";
import BeautyAndCosmetics from "./homepages/BeautyAndCosmetic";
import TopSection from "./homepages/TopSection";
import OwlCrousel from "./homepages/OwlCrousel";

const HomePage = () => {
  return (
    <>
      <Banner />
      {/* <TopSection /> */}
      <ProductCategories />
      <OwlCrousel />
      <FruitsAndVegetables />
      <SnacksAndMunchies />
      <ColdDrinksAndjuices />
      <Grocery />
      <BeautyAndCosmetics />
    </>
  );
};

export default HomePage;
