import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import img from "../../assets/images/IMG_20200331_213113_881.jpg";
import { LuFileSymlink } from "react-icons/lu";
import { FaRegUserCircle, FaWallet } from "react-icons/fa";
import { RiContactsLine } from "react-icons/ri";
import { TbLogout2 } from "react-icons/tb";
import { LiaWalletSolid } from "react-icons/lia";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import Swal from "sweetalert2";
import axios from "axios";
import { ContextApi } from "../../components/ContextApi/AppProvider";

const Sidebar = () => {
  const [islogin, setIslogin] = useState(false);
  const navigate = useNavigate();
  const { domainApi } = useContext(ContextApi);
  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    profile: "",
    gender: "",
    dob: "",
    phone: "",
  });
// console.log("sidebar");
  useEffect(() => {
    fetchAddress();
  }, [userData]);

  const fetchAddress = async () => {
    try {
      const res = await axios.get(`${domainApi}auth/profile/`, {
        headers: { Authorization: `TOKEN ${token}` },
      });
      if (res.status === 200) {
        setUserData(res.data);
      }
    } catch (error) {
      console.log("There Was A Problem With Address Api", error);
    }
  };
  
  const logOut = () => {
    setIslogin(false);
    localStorage.removeItem("token");
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "success",
      title: "Log Out Successful",
    });
    navigate('/');
  };

  const root = [
    {
      name: <Link to={`/user-dashboard/my-order`}>MY-ORDER <MdOutlineKeyboardDoubleArrowRight className="fs-4 ms-5 mb-1" /></Link>,
      icon: <LuFileSymlink />,
      path: "/user-dashboard/my-order",
    },
    {
      name: "ACCOUNT SETTINGS",
      icon: <FaRegUserCircle />,
      subroute: [
        {
          name: "Profile Information",
          path: "/user-dashboard/profile",
        },
        {
          name: "Manage Address",
          path: "/user-dashboard/address",
        }
      ],
    },
    {
      name: <Link title="Log-Out">LOG-OUT</Link>,
      icon: <TbLogout2 />,
      onClick: logOut,
    },
  ];

  return (
    <section className="use_dashboard py-4">
      <div className="container">
        <div className="row text-dark">
          <div className="col-lg-3 col-md-4">
            <div className="row user-name">
              <div className="col-lg-2 col-3 pe-0 px-lg-2 user_title_img d-flex align-items-center">
                <img src={userData.profile} alt={userData.name} className="img-fluid rounded-circle" />
              </div>
              <div className="col-lg-10 col-9 px-0 px-lg-4 px-md-4 d-flex align-items-center">
              <div className="">
                <p className="m-0">Hello</p>
                <h6 className="m-0">{userData.name}</h6>
                </div>
              </div>
            </div>

            <div className="user-details row mt-3 pb-3">
              {root.map((item, index) => (
                <div className="col-12 m-1" key={index}>
                  <p className="d-flex align-items-center" onClick={item.onClick}>
                    <span className="me-4 fs-5">{item.icon}</span>
                    {item.name}
                  </p>
                  {item.subroute && (
                    <ul className="list-unstyled">
                      {item.subroute.map((subItem, subIndex) => (
                        <li key={subIndex} className="user-dashboard-subroute">
                          <Link to={subItem.path} className="text-decoration-none text-dark">{subItem.name}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="col-lg-9 col-md-8">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
