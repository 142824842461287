import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import Swal from "sweetalert2";

const ProductCartButton = React.memo(({
  value,
  handleToggle,
  handleQuantityChange,
  productQuantity,
  toggle,
}) => {

  const handleToggleClick = () => {
    handleToggle(value.id);
  };

  const getQuantity = (productId) => {
    const item = productQuantity.cart_items.find(
      (item) => item.product_id === productId
    );
    return item ? item.quantity : 0;
  };

  const handleIncrement = () => {
    if (getQuantity(value.id) + 1 <= value.quantity_in_stock) {
      handleQuantityChange(value.id, true);
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: "info",
        title: `Only ${value.quantity_in_stock} quantity in stock`,
      });
    }
  };

  const handleDecrement = () => {
    handleQuantityChange(value.id, false);
  };

  return (
    <div className="crt-plus">
      {!toggle[value.id] ? (
        <button
          className="detail-btn d-flex align-items-center justify-content-center"
          onClick={handleToggleClick}
        >
          <FaPlus />
        </button>
      ) : (
        <div className="add-to-cart">
          <button className="icon-btn" onClick={handleDecrement}>
            <FaMinus />
          </button>
          <span className="px-2">{getQuantity(value.id)}</span>
          <button className="icon-btn" onClick={handleIncrement}>
            <FaPlus />
          </button>
        </div>
      )}
    </div>
  );
});

export default ProductCartButton;
