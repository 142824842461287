import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { ContextApi } from "../../ContextApi/AppProvider";

const OtpVerify = () => {
  const {domainApi} = useContext(ContextApi);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [inputOtp, setInputOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const location = useLocation();
  const { mobileNumber } = location.state ;
  const [resendCountdown, setResendCountdown] = useState(0);
  const [resendClickCount, setResendClickCount] = useState(0);
  const [resendToggle, setResendToggle] = useState(true);

  useEffect(() => {
    if (resendCountdown === 0) {
      setResendCountdown(6); // Reset countdown timer to 60 seconds
      setResendClickCount((prevCount) => prevCount + 1);
    }
  }, []);

  useEffect(() => {
    setPhoneNumber(mobileNumber)
    let countdownInterval;
    if (resendCountdown > 0 && resendClickCount <= 3) {
      countdownInterval = setInterval(() => {
        setResendCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else if (resendCountdown === 0 && resendClickCount > 0) {
      clearInterval(countdownInterval);
      setResendToggle(false);
    }
    // else if(resendClickCount >= 3){
    //   setResendToggle(false)
    // }
    return () => {
      clearInterval(countdownInterval);
    };
  }, [resendCountdown, resendClickCount]);

  const handleVerify = async (event) => {
    console.log({ phone: phoneNumber, otp: inputOtp });
    event.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          `${domainApi}auth/verify-otp/`,
          { phone: phoneNumber, otp: inputOtp }
        );
        console.log("response", response);
        if (response.status === 200) {
          console.log("response", response);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "OTP Verified successfully",
          });
          navigate("/login");
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "error",
            title: "server error",
            text: response.data.message,
          });
        }
      } catch (error) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "error",
          title: error.response.data.message,
          text: error.response.data.message,
        });
        console.log("there was a problem with your post operation", error);
      }
    }
  };

  const handleResendOtp = async () => {
    setResendToggle(true);

    if (resendCountdown === 0) {
      setResendCountdown(6); 
      setResendClickCount((prevCount) => prevCount + 1);
    }

    //     try{
    //     const response = await axios.post('https://portal.m4bistro.in/auth/resend-otp/',{phone:phoneNumber})
    //     console.log("response",response);
    //     if(response.status===200){
    //       console.log("response",response);
    //   const Toast = Swal.mixin({
    //     toast: true,
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 3000,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //       toast.onmouseenter = Swal.stopTimer;
    //       toast.onmouseleave = Swal.resumeTimer;
    //     },
    //   });
    //   Toast.fire({
    //     icon: "success",
    //     title: "Re-Send OTP successfully",
    //   });
    //     }else{
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'server error try again',
    //     // text: response.data.message
    // });
    // }
    //   }catch(error){
    // console.log("there was a problam with your post aperation",error);
    //   }
  };

  const handleSubmit = (event) => {
    //   event.preventDefault();
    //   // console.log(event.target.value);
  };

  const handleOtpKeyPress = (event) => {
    // Check if the entered key is a number and the length of the input is less than 4
    if (isNaN(Number(event.key)) || inputOtp.length >= 6) {
      event.preventDefault();
    }
  };
  const validateForm = () => {
    let errors = {};
    let isValid = true;
    const otpPattern = /^\d{6}$/;

    if (inputOtp.length < 6 || !otpPattern.test(inputOtp)) {
      errors.otp = "Invalid otp ";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  return (
    <>
      <div className="otp-verification-form">
        <h2>OTP Verification</h2>
        <form onSubmit={handleSubmit}>
          {/* <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              readOnly
              required
            />
          </div> */}
          <>
            <div className="form-group">
              <label htmlFor="otp">OTP</label>
              <input
                placeholder="Enter Valid Otp"
                type="text"
                id="otp"
                name="otp"
                value={inputOtp}
                onChange={(e) => setInputOtp(e.target.value)}
                onKeyPress={handleOtpKeyPress} // Validation on key press
                required
              />
              {errors.otp && (
                <span className="error" style={{ color: "red" }}>
                  {errors.otp}
                </span>
              )}
            </div>
            <button
              type="submit"
              className="btn-primary"
              onClick={handleVerify}
            >
              Verify OTP
            </button>

            {resendToggle ? (
              <p className="my-2">Resend in {resendCountdown} seconds</p>
            ) : (
              <div className="my-2">
                <button
                  type="button"
                  className={`${resendClickCount === 3 ? "d-none" :'' }  btn border-none shadow`}
                  onClick={handleResendOtp}
                  disabled={resendClickCount < 0}
                >
                  Re-send OTP
                </button>
                {resendCountdown > 0 && (
                  <p>Resend in {resendCountdown} seconds</p>
                )}
                {resendClickCount >= 3 && (
                  <p>Maximum resend attempts reached Try Again After 1 Hour</p>
                )}
              </div>
            )}
          </>
        </form>
      </div>
    </>
  );
};

export default OtpVerify;

// // const location = useLocation();
// // const queryParams = new URLSearchParams(location.search);
// // const [phoneNumber, setPhoneNumber] = useState(queryParams.get("phone") || "");
// // const [otp, setOtp] = useState(queryParams.get("otp") || "");
// // console.log("otp",otp);
// // console.log("num",phoneNumber);
//  //     if(validOtp == inputOtp){
//     //       alert('otp verified ')
//     //       navigate("/signup/login");
//     //     }else(
//     //       alert('please enter valid otp')
//     //     )

// import { useOtp } from "../../CartContext/otpContet";
// const { otpData } = useOtp();
// console.log("locaion", mobileNumber);
// console.log(phoneNumber);
// const phoneNumber = otpData.phone;
// const [validOtp, setValidOtp] = useState(otpData.otp);
// console.log(otpData.phone)
// console.log(phoneNumber)

//   import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Swal from "sweetalert2";

// const OtpVerify = () => {
//   const navigate = useNavigate();
//   const [errors, setErrors] = useState({});
//   const [inputOtp, setInputOtp] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   // const location = useLocation();
//   // const { mobileNumber } = location.state ;
//   const [resendCountdown, setResendCountdown] = useState(0);
//   const [resendClickCount, setResendClickCount] = useState(0);
//   const [resendToggle, setResendToggle] = useState(true);

//   useEffect(() => {
//     // setPhoneNumber(mobileNumber)

//     let countdownInterval;

//     if (resendCountdown > 0 && resendClickCount < 3) {
//       countdownInterval = setInterval(() => {
//         setResendCountdown((prevCount) => prevCount - 1);
//       }, 1000);
//     } else if (resendCountdown === 0 && resendClickCount > 0) {
//       clearInterval(countdownInterval);
//     }
//     // else if (resendCountdown === 6 && resendClickCount === 6) {
//       // clearInterval(countdownInterval);
//       // setResendToggle(false)
//     // }

//     return () => {
//       clearInterval(countdownInterval);
//     };
//   }, [resendCountdown, resendClickCount]);

//   const handleVerify = async (event) => {
//     console.log({ phone: phoneNumber, otp: inputOtp });
//     event.preventDefault();
//     if (validateForm()) {
//       // try {
//       //   const response = await axios.post(
//       //     "http://127.0.0.1:8000/auth/verify-otp/",
//       //     { phone: phoneNumber, otp: inputOtp }
//       //   );
//       //   console.log("response", response);
//       //   if (response.status === 200) {
//       //     console.log("response", response);
//       //     const Toast = Swal.mixin({
//       //       toast: true,
//       //       position: "top-end",
//       //       showConfirmButton: false,
//       //       timer: 3000,
//       //       timerProgressBar: true,
//       //       didOpen: (toast) => {
//       //         toast.onmouseenter = Swal.stopTimer;
//       //         toast.onmouseleave = Swal.resumeTimer;
//       //       },
//       //     });
//       //     Toast.fire({
//       //       icon: "success",
//       //       title: "OTP Verified successfully",
//       //     });
//       //     navigate("/login");
//       //   } else {
//       //     Swal.fire({
//       //       icon: "error",
//       //       title: "server error try again",
//       //       // text: response.data.message
//       //     });
//       //   }
//       // } catch (error) {
//       //   console.log("there was a problem with your post operation", error);
//       // }
//     }
//   };

//   useEffect(()=>{
//     if (resendCountdown === 0) {
//       setResendCountdown(6); // Reset countdown timer to 60 seconds
//       setResendClickCount((prevCount) => prevCount + 1);
//     }
//     // setResendToggle(false)
//   },[])

//   const handleResendOtp = async () => {
//     if (resendCountdown === 0) {
//       setResendCountdown(6); // Reset countdown timer to 60 seconds
//       setResendClickCount((prevCount) => prevCount + 1);
//     }
//     //     try{
//     //     const response = await axios.post('https://portal.m4bistro.in/auth/resend-otp/',{phone:phoneNumber})
//     //     console.log("response",response);
//     //     if(response.status===200){
//     //       console.log("response",response);
//     //   const Toast = Swal.mixin({
//     //     toast: true,
//     //     position: "top-end",
//     //     showConfirmButton: false,
//     //     timer: 3000,
//     //     timerProgressBar: true,
//     //     didOpen: (toast) => {
//     //       toast.onmouseenter = Swal.stopTimer;
//     //       toast.onmouseleave = Swal.resumeTimer;
//     //     },
//     //   });
//     //   Toast.fire({
//     //     icon: "success",
//     //     title: "Re-Send OTP successfully",
//     //   });
//     //     }else{
//     //   Swal.fire({
//     //     icon: 'error',
//     //     title: 'server error try again',
//     //     // text: response.data.message
//     // });
//     // }
//     //   }catch(error){
//     // console.log("there was a problam with your post aperation",error);
//     //   }
//   };

//   const handleSubmit = (event) => {
//     //   event.preventDefault();
//     //   // console.log(event.target.value);
//   };

//   const handleOtpKeyPress = (event) => {
//     // Check if the entered key is a number and the length of the input is less than 4
//     if (isNaN(Number(event.key)) || inputOtp.length >= 6) {
//       event.preventDefault();
//     }
//   };
//   const validateForm = () => {
//     let errors = {};
//     let isValid = true;
//     const otpPattern = /^\d{6}$/;

//     if (inputOtp.length < 6 || !otpPattern.test(inputOtp)) {
//       errors.otp = "Invalid otp ";
//       isValid = false;
//     }
//     setErrors(errors);
//     return isValid;
//   };

//   return (
//     <>
//       <div className="otp-verification-form">
//         <h2>OTP Verification</h2>
//         <form onSubmit={handleSubmit}>
//           {/* <div className="form-group">
//             <label htmlFor="phoneNumber">Phone Number</label>
//             <input
//               type="text"
//               id="phoneNumber"
//               value={phoneNumber}
//               readOnly
//               required
//             />
//           </div> */}
//           <>
//             <div className="form-group">
//               <label htmlFor="otp">OTP</label>
//               <input
//                 placeholder="Enter Valid Otp"
//                 type="text"
//                 id="otp"
//                 name="otp"
//                 value={inputOtp}
//                 onChange={(e) => setInputOtp(e.target.value)}
//                 onKeyPress={handleOtpKeyPress} // Validation on key press
//                 required
//               />
//               {errors.otp && (
//                 <span className="error" style={{ color: "red" }}>
//                   {errors.otp}
//                 </span>
//               )}
//             </div>
//             <button
//               type="submit"
//               className="btn-primary"
//               onClick={handleVerify}
//             >
//               Verify OTP
//             </button>

//             {resendToggle ? (
//               <p>Resend in {resendCountdown} seconds</p>
//             ) : (
//               <div>
//                 <button
//                   type="button"
//                   className="btn-secondary"
//                   onClick={handleResendOtp}
//                   disabled={resendCountdown > 0}
//                 >
//                   Re-Send OTP
//                 </button>
//                 {resendCountdown > 0 && (
//                   <>
//                     <p>Resend in {resendCountdown} seconds</p>
//                   </>
//                 )}
//                 {resendClickCount >= 3 && (
//                   <p>Maximum resend attempts reached</p>
//                 )}
//               </div>
//             )}
//           </>
//         </form>
//       </div>
//     </>
//   );
// };

// export default OtpVerify;
