import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Modal from "react-modal";
import AddToCart from "../ShowCarts/AddToCart";
import { ContextApi } from "../../ContextApi/AppProvider";
import axios from "axios";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import { CartContextApi } from "../../ContextApi/CartContext";
import WishList from "../ShowCarts/WishList";

const Header = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [islogin, setIslogin] = useState(false);
  const [stateNo, setStateNo] = useState(0);
  const token = localStorage.getItem("token");
  const { ApiData } = useContext(ContextApi);
  const { emptyLocalStorageLogOut } = useContext(CartContextApi);
  const { currentAddress } = useContext(ContextApi);
  const [address, setAddress] = useState("");
  const [cityState, setCityState] = useState("Indore Madhya Pradesh (MP)");
  const [loadingMap, setLoadingMap] = useState(false);
  const [search, setSearch] = useState("");
  const { searchValue } = useContext(ContextApi);
  // const ref_header_searchbar = useRef(null);
  // const ref_header_dropdown = useRef(null);
  // const ref_header_logo = useRef(null);

  // setTimeout(() => {
  //   setStateNo(stateNo < 10 ? stateNo + 1 : 0);
  // }, 2050);

  // useEffect(() => {
  //   if (window.location.pathname === "/search-products") {
  //     if (window.outerWidth >= 360 && window.outerWidth <= 400) {
  //       ref_header_dropdown.current.style.display = "none";
  //     }else {
  //   }}else{
  //     ref_header_dropdown.current.style.display = "block";
  //   }
  // }, [window.location.pathname, window.outerWidth]);

  useEffect(() => {
    if (token) {
      setIslogin(true);
    } else {
      setIslogin(false);
    }
  }, [token]);

  const getCurrentAddress = async () => {
    currentAddress(cityState);
    setLoadingMap(true);
    try {
      const permission = await navigator.permissions.query({
        name: "geolocation",
      });
      if (permission.state === "denied") {
        alert("Please enable location services in your browser settings.");
        return;
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDKeCyRs2FvhPpAipxstruQEKV5vJ0NIzA`
              );
              // const data = await response.json();
              // console.log(response.data)
              if (response.data.results.length > 0) {
                // console.log(response.data.results[0].formatted_address);
                setAddress(response.data.results[0].formatted_address);
                setCityState(response.data.results[7].formatted_address);
                currentAddress(response.data.results[0].formatted_address);
              } else {
                setAddress("Address not found");
              }
            } catch (error) {
              console.error("Error fetching address:", error);
              setAddress("Error fetching address");
            } finally {
              setLoadingMap(false);
            }
          },
          (error) => {
            console.error("Error getting location:", error);
            setAddress("Error getting location");
            setLoadingMap(false);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser");
        setAddress("Geolocation is not supported");
        setLoadingMap(false);
      }
    } catch (error) {
      console.error("Error checking permission:", error);
      setAddress("Error checking permission");
      setLoadingMap(false);
    }
  };
  useEffect(() => {
    getCurrentAddress();
  }, []);

  const navigate = useNavigate();

  const logOut = () => {
    emptyLocalStorageLogOut();
    setIslogin(false);
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    Toast.fire({
      icon: "success",
      title: "Log Out Successful",
    });
    navigate("/");
  };

  const handleChange = (event) => {
    searchValue(event.target.value);
    setSearch(event.target.value);
  };
  const handleSearchClick = () => {
    navigate("/search-products");
  };
  // console.log(window.location.pathname)
  return (
    <>
      <div className="container-fluid  nav-sec" id="nav-sec">
        <section className="header-nav-sec">
          <div className="row">
            <nav className="navbar navbar-expand-lg main-menu">
              <div className="main-header">
                <Link to="/">
                  <div className="navbar-logo">
                    {/* //</Link>ref={ref_header_logo}> */}
                    <img src={ApiData.siteConfig.logo} alt="logo" />
                  </div>
                </Link>
                <div className="location-header">
                  <span onClick={() => setModalIsOpen(true)}>
                    {cityState}
                    <i className="fa-solid fa-caret-down"></i>
                  </span>

                  {/* <!-- location-modal --> */}
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    contentLabel="Example Modal"
                  >
                    <div className="overlay"></div>
                    <div className="cardbox" id="cardbox">
                      <div className="icon-right">
                        <span>Your New Location</span>
                        <i
                          className="fa-solid fa-xmark closebtn"
                          onClick={() => setModalIsOpen(false)}
                        ></i>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="search new location"
                      />
                      <div
                        className="loc-gps mt-3"
                        // onClick={getCurrentAddress}
                        disabled={loadingMap}
                      >
                        <h6 className="mb-0">
                          <i className="fa-solid fa-location-crosshairs"></i>{" "}
                          {loadingMap ? "Loadin..." : "Get Current Address"}
                        </h6>
                        <small>Detech GPS</small>
                      </div>
                      <div>
                        <p>Address :- {address}</p>
                      </div>
                    </div>
                  </Modal>
                  {/* <!-- end --> */}
                </div>

                {/* ------------- search bar start -------------- */}
                <div className="search-location" onClick={handleSearchClick}>
                  {/* ref={ref_header_searchbar}  */}
                  <input
                    type="search"
                    className="form-control"
                    id="search_btn"
                    value={
                      window.location.pathname === "/search-products"
                        ? search
                        : ""
                    }
                    onChange={handleChange}
                  />
                  <span
                    className={`${
                      window.location.pathname === "/search-products" && search
                        ? "s-bottom-none"
                        : "s-bottom"
                    }`}
                    id="scroll-text"
                  >
                    Search "{ApiData.productCategories[stateNo].title}"
                  </span>
                  <i
                    className="fa-solid fa-magnifying-glass"
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>

                <div className="login-sec">
                  <div className="login-left">
                    <div>
                      {/* ref={ref_header_dropdown} */}
                      {!islogin ? (
                        <div className="login-without-token">
                          <Link to="/login" id="login_form_btn">
                            <span>
                              <i className="fa-regular fa-circle-user"></i>
                              Log-In
                            </span>
                          </Link>
                        </div>
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <span>
                              <i className="fa-regular fa-circle-user"></i>
                            </span>
                            Account
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              as={Link}
                              to="/user-dashboard/profile"
                            >
                              My Profile
                            </Dropdown.Item>
                            <Dropdown.Item
                              as={Link}
                              to="/user-dashboard/my-order"
                            >
                              My Orders
                            </Dropdown.Item>
                            <Dropdown.Item onClick={logOut}>
                              Log Out
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                    {/* {token ? (
                      <div className="header-fav-cart">
                        <WishList />
                      </div>
                    ) : (
                      ""
                    )} */}

                    <div className="header-add-to-cart">
                      <AddToCart />
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </section>
      </div>
    </>
  );
};
export default Header;
