import React, { useContext, useEffect, useState } from "react";
import ProductCategories from "./homepages/ProductCategories";
import OwlCrousel from "./homepages/OwlCrousel";
import { ContextApi } from "../ContextApi/AppProvider";
import ProductNormalCart from "./homepages/ProductNormalCart";

const SearchProducts = () => {
  const { searchData, ApiData } = useContext(ContextApi);
  const [searchDataLowCare, setSearchDataLowCase] = useState("");


  useEffect(() => {
    if (typeof searchData === "string") {
      setSearchDataLowCase(searchData.toLowerCase());
    }
  }, [searchData, ApiData]);

  const filterData = ApiData.allProducts.filter((item) =>
    item.title.toLowerCase().includes(searchDataLowCare)
  );

  const filterCategoryData = ApiData.allProducts.filter((item) =>
    item.category.title.toLowerCase().includes(searchDataLowCare)
  );

  // If filterData is empty, use filterCategoryData
  const finalFilterData =
    filterData.length > 0 ? filterData : filterCategoryData;

  return (
    <>
      {finalFilterData &&
      searchData &&
      searchData.length > 0 &&
      finalFilterData.length > 0 ? (
        <section className="about-home py-4">
          <div className="container">
            <h3 className="fs-4 text-center fw-bold mb-3">Search Results For : {searchData}</h3>
            <div className="row">
              {finalFilterData && finalFilterData.length > 0 && (
                <ProductNormalCart value={finalFilterData} />
              )}
            </div>
          </div>
        </section>
      ) : (
        <>
          <ProductCategories />
          <OwlCrousel />
        </>
      )}
    </>
  );
};

export default SearchProducts;
