import axios from "axios";
import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import { ContextApi } from "../ContextApi/AppProvider";
import { FaStar } from "react-icons/fa";

const Feedback = () => {
  const { domainApi } = useContext(ContextApi);
  const [formData, setFormData] = useState({
    name: "",
    message: "",
    rating: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleStarClick = (value) => {
    setFormData((formData) => ({
      ...formData,
      rating: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${domainApi}core/feedback/`, formData);
      if (res.status === 201) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Your feedback has been submitted successfully.",
        });
        setFormData({
          name: "",
          message: "",
          rating: "",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Server error, please try again",
        });
      }
    } catch (error) {
      console.log("There was a problem with your POST API", error);
    }
  };

  return (
    <>
      <div className="container my-3">
        <div className="row">
          <div className="col-md-8 col-lg-6 mx-auto">
            <div className="feedback-form w-100">
              <h3 className="text-center mb-4">Feedback Form</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows="3"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label className="form-label">Rating</label>
                  <div className="star-rating">
                    <ul
                      className="d-flex p-0"
                      style={{ listStyleType: "none" }}
                    >
                      {[1, 2, 3, 4, 5].map((value) => (
                        <li
                          key={value}
                          className={`star fs-2 mx-3 ${
                            value <= formData.rating ? "yellow" : ""
                          }`}
                          onClick={() => handleStarClick(value)}
                          style={{
                            cursor: "pointer",
                            color:
                              value <= formData.rating ? "#7655ab" : "gray",
                          }}
                        >
                          <FaStar />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
