import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ContextApi } from "../../ContextApi/AppProvider";
import { IoLocationOutline } from "react-icons/io5";
import { LuHotel } from "react-icons/lu";
import { AiOutlineHome } from "react-icons/ai";
import { MdOutlineAssuredWorkload } from "react-icons/md";

const Address = ({
  updateAddress,
  close,
  currentLoction,
  latitude,
  longitude,
}) => {
  const { domainApi } = useContext(ContextApi);
  const userId = localStorage.getItem("userid");
  const token = localStorage.getItem("token");

  const [address, setAddress] = useState({
    user: userId,
    customer_name: "",
    phone: "+91",
    address_type: "",
    building_name: "",
    floor: "",
    landmark: "",
    full_address: currentLoction,
    latitude: latitude,
    longitude: longitude,
  });

  useEffect(() => {
    if (updateAddress) {
      setAddress({
        user: userId,
        customer_name: updateAddress.customer_name,
        phone: updateAddress.phone,
        address_type: updateAddress.address_type,
        building_name: updateAddress.building_name,
        floor: updateAddress.floor,
        landmark: updateAddress.landmark,
        full_address: currentLoction,
        latitude: latitude,
        longitude: longitude,
      });
    } else {
      setAddress({
        user: userId,
        customer_name: "",
        phone: "+91",
        address_type: "",
        building_name: "",
        floor: "",
        landmark: "",
        full_address: currentLoction,
        latitude: latitude,
        longitude: longitude,
      });
    }
  }, [updateAddress, currentLoction, latitude, longitude, userId]);

  console.log("addresscomponent")

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleAddressTypeChange = (value) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      address_type: value,
    }));
  };

  const saveNewAddress = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(
        `${domainApi}product/shipping-address/`,
        address,
        { headers: { Authorization: `TOKEN ${token}` } }
      );
      console.log(res);
      if (res.status === 201) {
        console.log(res);
        close();
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Address successfully saved",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Server error, please try again",
        });
      }
    } catch (error) {
      console.error("There is a problem with the API", error);
    }
  };
  const updateNewAddress = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.patch(
        `${domainApi}product/shipping-address/${updateAddress.id}/`,
        address,
        { headers: { Authorization: `TOKEN ${token}` } }
      );
      if (res.status === 200) {
        // console.log(res);
        close();
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Address Update successfully",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Server error, please try again",
        });
      }
    } catch (error) {
      console.error("There is a problem with the API", error);
    }
  };

  return (
    <form className="Address-form">
      <div>
        <h6>Enter Complete Address</h6>
        <div>
          Address Type:
          <ul className="d-flex justify-content-between">
            <li
              className={`address-type-btn ${
                address.address_type === "home" ? "active" : ""
              }`}
              onClick={() => handleAddressTypeChange("home")}
            >
              <span>
                <AiOutlineHome />
              </span>
              <span> Home </span>
            </li>
            <li
              className={`address-type-btn ${
                address.address_type === "work" ? "active" : ""
              }`}
              onClick={() => handleAddressTypeChange("work")}
            >
              <span>
                <MdOutlineAssuredWorkload />
              </span>
              <span> Work </span>
            </li>
            <li
              className={`address-type-btn ${
                address.address_type === "hotel" ? "active" : ""
              }`}
              onClick={() => handleAddressTypeChange("hotel")}
            >
              <span>
                <LuHotel />
              </span>
              <span> Hotel </span>
            </li>
            <li
              className={`address-type-btn ${
                address.address_type === "other" ? "active" : ""
              }`}
              onClick={() => handleAddressTypeChange("other")}
            >
              <span>
                <IoLocationOutline />
              </span>
              <span> Other </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <p>Area / Sector / Locality:</p>
        {address.full_address}
      </div>
      <div>
        <label>
          Flat/House No/Building (optional):
          <input
            name="building_name"
            className="form-control"
            type="text"
            value={address.building_name}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <label>
          Floor (optional):
          <input
            className="form-control"
            type="text"
            name="floor"
            value={address.floor}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <label>
          Nearby Landmark:
          <input
            className="form-control"
            type="text"
            name="landmark"
            value={address.landmark}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <label>
          Your Name:
          <input
            className="form-control"
            type="text"
            name="customer_name"
            value={address.customer_name}
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <label>
          Your Mobile No:
          <input
            className="form-control"
            type="text"
            name="phone"
            maxLength="13"
            value={address.phone}
            onChange={handleChange}
          />
        </label>
      </div>
      {updateAddress ? (
        <button type="submit" className="my-3" onClick={updateNewAddress}>
          Update Address
        </button>
      ) : (
        <button type="submit" className="my-3" onClick={saveNewAddress}>
          Save Address
        </button>
      )}
    </form>
  );
};

export default Address;
