import React, { useContext, useEffect, useState } from "react";
import { ContextApi } from "../../../components/ContextApi/AppProvider";
import axios from "axios";
import Swal from "sweetalert2";
import {
  FaBirthdayCake,
  FaEnvelope,
  FaPen,
  FaPhone,
  FaUser,
} from "react-icons/fa";

const Profile = () => {
  const { domainApi } = useContext(ContextApi);
  const token = localStorage.getItem("token");
  const [toggel, setToggel] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    profile: null,
    gender: "",
    dob: "",
    phone: "",
  });

  useEffect(() => {
    fetchAddress();
  }, []);

  const fetchAddress = async () => {
    try {
      const res = await axios.get(`${domainApi}auth/profile/`, {
        headers: { Authorization: `TOKEN ${token}` },
      });
      if (res.status === 200) {
        // console.log(res);
        setUserData({
          name: res.data.name,
          email: res.data.email,
          profile: res.data.profile,
          gender: res.data.gender,
          dob: res.data.dob,
          phone: res.data.phone,
        });
      }
    } catch (error) {
      console.log("There Was A Problem With Address Api", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;
    if (type === "file") {
      setUserData((prev) => ({
        ...prev,
        [name]: files[0], // Handle file input
      }));
    } else if (type === "radio") {
      setUserData((prev) => ({
        ...prev,
        gender: value, // Handle radio input
      }));
    } else {
      setUserData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  // console.log(userData)

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("name", userData.name);
    data.append("email", userData.email);
    data.append("gender", userData.gender);
    data.append("dob", userData.dob);
    if (userData.profile && typeof userData.profile !== "string") {
      data.append("profile", userData.profile); // Only append new profile image if it is selected
    }

    try {
      const response = await axios.patch(`${domainApi}auth/profile/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `TOKEN ${token}`,
        },
      });
      if (response.status === 200) {
        // console.log("response", response);
        setToggel(false);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Profile updated",
        });

        // Update userData state with the new profile data
        fetchAddress();
      } else {
        Swal.fire({
          icon: "error",
          title: "Server error, try again",
        });
      }
    } catch (error) {
      console.error("There was a problem with your post operation", error);
    }
  };

  return (
    <>
      <div className="">
        {!toggel ? (
          <div className="right_information space-y-4 md:space-y-0 md:space-x-6 md:flex-row">
            <h4 className="mb-4">Profile Information</h4>
            <div className="justify-center flex pro_img">
              <img
                src={userData.profile}
                alt={userData.name}
                title={userData.name}
                className="image_new self-center flex-shrink-0 w-24 h-24 hover:scale-105 transition duration-250 ease-in-out border rounded-full"
              />
              <div className="change_icon">
                <FaPen
                  onClick={() => setToggel(true)}
                  title="Update profile"
                  className="absolute -top-1 right-0 cursor-pointer text-gray-600 hover:text-gray-800"
                />
              </div>
            </div>
            <div className="flex flex-col px-2 space-y-2 font-serif text-justify">
              <p className="user_line mb-3">
                <FaUser className="change_icon_user me-2" />
                <span>Name: {userData.name}</span>
              </p>
              <p className="user_line flex items-center space-x-2 text-gray-600 mb-3">
                <FaEnvelope className="change_icon_user me-2" />
                <span>Email: {userData.email}</span>
              </p>
              <p className="user_line flex items-center space-x-2 text-gray-600 mb-3">
                <FaPhone className="change_icon_user me-2" />
                <span>Phone: {userData.phone}</span>
              </p>
              <p className="user_line flex items-center space-x-2 text-gray-600 mb-3">
                <FaUser className="change_icon_user me-2" />
                <span>Gender: {userData.gender}</span>
              </p>
              <p className="user_line flex items-center space-x-2 text-gray-600">
                <FaBirthdayCake className="change_icon_user me-2" />
                <span>DOB: {new Date(userData.dob).toLocaleDateString("en-GB")}</span>
              </p>
            </div>
          </div>
        ) : (
          /* update profile */
          <div className="row mb-1">
            <form className="col-lg-12 my-4" encType="multipart/form-data">
              <h6>Personal Information</h6>
              <div className="col-lg-12 ">
                <input
                  className="form-control"
                  placeholder="Full Name"
                  name="name"
                  value={userData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <h6 className="form-label">Profile</h6>
                <input
                  className="form-control"
                  type="file"
                  name="profile"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <h6 className="form-label">DOB</h6>
                <input
                  className="form-control"
                  type="date"
                  name="dob"
                  value={userData.dob}
                  onChange={handleChange}
                />
              </div>
              <div className="row my-2">
                <h6>Your Gender</h6>
                <div className="form-check col-lg-1 mx-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    value="Male"
                    checked={userData.gender === "Male"}
                    onChange={handleChange}
                  />
                  <label className="form-check-label">Male</label>
                </div>
                <div className="form-check col-lg-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    value="Female"
                    checked={userData.gender === "Female"}
                    onChange={handleChange}
                  />
                  <label className="form-check-label">Female</label>
                </div>
              </div>
              <div className="row my-2">
                <h6>Email Address</h6>
                <div className="col-lg-12">
                  <input
                    className="form-control"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <button className="btn btn-primary" type="submit" onClick={handleUpdate}>
                  Confirm Update
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default Profile;

