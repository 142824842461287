import axios from "axios";
import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Swal from "sweetalert2";
import LoadingPage from "../ScrollButton/LoadingPage";
export const ContextApi = createContext();

const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [ApiData, setApiData] = useState(null);
  // const doamainApi = "http://127.0.0.1:8000/";
  const domainApi = "https://portal.quickecommerce.m4bistro.in/";
  const token = localStorage.getItem("token");
  const [address, setAddress] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const currentAddress = (addrs) => {
    setAddress({ addrs });
  };
  const searchValue = (value) => {
    setSearchData(value);
  };

  //   const wishlistData = async () => {
  //     try {
  //       const res = await axios.get(`${domainApi}product/favorites/`, {
  //         headers: {
  //           Authorization: `TOKEN ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       if (res.status === 200) {
          
  //         setWishlist(res.data)
  //         // console.log("wishlist",res.data);
  //         // filterData(res.data);
  //       }
  //     } catch (error) {
  //       console.log("there Was a problem in favorite api", error);
  //     }
  //   };

  // const addToWishlist = async (id) => {
  //   try {
  //     const res = await axios.post(
  //       `${domainApi}product/favorites/`,
  //       { product: id },
  //       {
  //         headers: {
  //           Authorization: `TOKEN ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (res.status === 201) {
  //       // console.log(res);
  //       wishlistData()
  //     }
  //   } catch (error) {
  //     console.log("There Was a problem in add fav api", error);
  //   }
  // };
  // const deleteFromWishlist = async (id) => {
  //   try {
  //     const res = await axios.delete(`${domainApi}product/favorites/${id}`, {
  //       headers: {
  //         Authorization: `TOKEN ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     console.log(res);
  //     if (res.status === 204) {
  //       // console.log(res);
  //       wishlistData()
  //     }
  //   } catch (error) {
  //     console.log("There Was a problem in del fav api", error);
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [
          siteConfigRes,
          socialMediaRes,
          bannerRes,
          productCategoriesRes,
          allProductsRes,
          aboutUsRes,
          termsAndConditionsRes,
          privacyAndPolicyRes,
          faqsRes,
          // wishlistRes,
        ] = await Promise.all([
          axios.get(`${domainApi}/core/site/`),
          axios.get(`${domainApi}/core/social-media/`),
          axios.get(`${domainApi}/vendor/banners/`),
          axios.get(`${domainApi}/product/categories/`),
          axios.get(`${domainApi}/product/products/`),
          axios.get(`${domainApi}/core/about-us/`),
          axios.get(`${domainApi}/core/terms-condition/`),
          axios.get(`${domainApi}/core/privacy-policy/`),
          axios.get(`${domainApi}/core/faq/`),
          // axios.get(`${domainApi}product/favorites/`, {
          //   headers: {
          //     Authorization: `TOKEN ${token}`,
          //     "Content-Type": "application/json",
          //   },
          // }),
        ]);
        setApiData({
          siteConfig: siteConfigRes.data,
          socialMedia: socialMediaRes.data,
          banner: bannerRes.data,
          productCategories: productCategoriesRes.data,
          allProducts: shiftEndOutOfStock(allProductsRes.data),
          aboutUs: aboutUsRes.data,
          termsAndConditions: termsAndConditionsRes.data,
          privacyAndPolicy: privacyAndPolicyRes.data,
          faqs: faqsRes.data,
          // wishlist: wishlistRes.data,
        });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops, try again....",
          text: " Server",
          error,
        });
        console.error(error || "An error occurred");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // wishlistData();
  }, [domainApi, token]);

  const shiftEndOutOfStock = (item) => {
    setLoading(true);
    let Is_Available = [];
    let Not_Available = [];
    for (let i = 0; i < item.length; i++) {
      if (item[i].is_available) {
        Is_Available.push(item[i]);
      } else {
        Not_Available.push(item[i]);
      }
    }
    for (let i = 0; i < Not_Available.length; i++) {
      Is_Available.push(Not_Available[i]);
    }
    setLoading(false);
    return Is_Available;
  };
  
  // console.log("wishlist", wishlist);
  console.log("apiresponse", ApiData);

  const ContextValue = useMemo(
    () => ({
      ApiData,
      domainApi,
      address,
      searchData,
      searchValue,
      currentAddress,
      // addToWishlist,
      // deleteFromWishlist,
      // wishlist,
    }),
    [ApiData,searchData]
  );

  if (loading || ApiData === null) {
    return (
      <>
        <LoadingPage />
      </>
    );
  }

  return (
    <>
      <ContextApi.Provider value={ContextValue}>{children}</ContextApi.Provider>
    </>
  );
};
export default AppProvider;
