// AIzaSyD64OumsUVpMYctbG34ovIoXogYrjejS-4
// AIzaSyDKeCyRs2FvhPpAipxstruQEKV5vJ0NIzA
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import { FaMapMarkerAlt } from "react-icons/fa"; // Import the map marker icon
import Address from "./Address";
import { ContextApi } from "../../ContextApi/AppProvider";

const LocationMap = ({
  center,
  zoom,
  onClick,
  markerPosition,
  getCurrentLocation,
  searchLocation,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setLoading(true); // Set loading to true when search starts
    searchLocation(searchQuery); // Call the searchLocation function with the search query
  };

  // const handleSearch = () => {
  //   setLoading(true); // Set loading to true when search starts
  //   searchLocation(searchQuery); // Call the searchLocation function with the search query
  // };

  return (
    <div style={{ height: "500px", width: "100%", position: "relative" }}>
      <div
        className="address-map-search"
        style={{
          position: "absolute",
          top: "10px",
          left: "30%",
          transform: "translateX(-50%)",
          zIndex: "1",
        }}
      >
        <input
          className="form-control w-100"
          type="search"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search location..."
        />
        {/* <input className='form-control' type="search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search location..." /> */}
        {/* <button className='' onClick={handleSearch}>Search</button> */}
        {loading && <div>Loading...</div>} {/* Show loading indicator */}
      </div>
      {/* Map */}
      <div style={{ height: "calc(100% - 40px)", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDKeCyRs2FvhPpAipxstruQEKV5vJ0NIzA" }} // Replace 'AIzaSyDKeCyRs2FvhPpAipxstruQEKV5vJ0NIzA' with your actual Google Maps API key
          center={center}
          defaultZoom={zoom}
          onClick={onClick}
        >
          {/* Render the custom marker icon at the specified position */}
          {markerPosition && (
            <Marker lat={markerPosition.lat} lng={markerPosition.lng} />
          )}
        </GoogleMapReact>
      </div>
      {/* Get current location button */}
      <button
        className="btn get-crrent-location-btn"
        style={{ position: "absolute", bottom: "64px", left: "10px" }}
        onClick={getCurrentLocation}
      >
        Get Current Location
      </button>
    </div>
  );
};

const Marker = () => (
  <FaMapMarkerAlt style={{ color: "red", fontSize: "24px" }} />
); // Define the custom marker icon

const MapContainer = ({ close, updateAddress }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null); // State to store the position of the custom marker
  const { address } = useContext(ContextApi);
  const [getCurrentLoction, setGetCurrentLoction] = useState(address.addrs);

  useEffect(() => {
    getLocation();
  }, []);
  console.log("googlemapcomponent")

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setError(null);
          getAddressFromCoordinates(
            position.coords.latitude,
            position.coords.longitude
          );
          setMarkerPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }); // Set the position of the custom marker
        },
        (error) => {
          setError("Geolocation error: " + error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const getAddressFromCoordinates = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDKeCyRs2FvhPpAipxstruQEKV5vJ0NIzA`
      );
      setGetCurrentLoction(response.data.results[0].formatted_address);
    } catch (error) {
      setError("Error fetching address from coordinates", error);
    }
  };

  const handleMapClick = (event) => {
    setLatitude(event.lat);
    setLongitude(event.lng);
    getAddressFromCoordinates(event.lat, event.lng);
    setMarkerPosition({ lat: event.lat, lng: event.lng }); // Set the position of the custom marker when clicked on the map
  };

  const getCurrentLocation = () => {
    getLocation(); // Call the getLocation function to get the current location
  };

  const searchLocation = async (searchQuery) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${searchQuery}&key=AIzaSyDKeCyRs2FvhPpAipxstruQEKV5vJ0NIzA`
      );
      const { lat, lng } = response.data.results[0].geometry.location;
      setLatitude(lat);
      setLongitude(lng);
      getAddressFromCoordinates(lat, lng);
      setMarkerPosition({ lat, lng }); // Set the position of the custom marker to the searched location
      // setLoading(false); // Set loading to false after receiving response
    } catch (error) {
      setError("Error fetching location from search query");
      // setLoading(false); // Set loading to false if there's an error
    }
  };

  return (
    <>
      <section className="address_model">
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-12">
            {latitude && longitude && (
              <>
                <LocationMap
                  center={{ lat: latitude, lng: longitude }}
                  zoom={15}
                  onClick={handleMapClick}
                  markerPosition={markerPosition} // Pass the marker position to the LocationMap component
                  getCurrentLocation={getCurrentLocation} // Pass the getCurrentLocation function to the LocationMap component
                  searchLocation={searchLocation} // Pass the searchLocation function to the LocationMap component
                />
                <div>
                  Latitude: {latitude}, Longitude: {longitude}
                </div>
                {/* <div>Address: {address}</div> */}
                {error && <div>Error: {error}</div>}
              </>
            )}
          </div>
          <>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Address
                updateAddress={updateAddress}
                close={close}
                currentLoction={getCurrentLoction}
                latitude={latitude}
                longitude={longitude}
              />
            </div>
          </>
        </div>
      </section>
    </>
  );
};

export default MapContainer;
